import React, {useState} from 'react';
import {
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  Grid2,
  MenuItem,
  TextField,
  Typography
} from "@mui/material";
import {MuiTelInput} from "mui-tel-input";
import {DateTimePicker, LocalizationProvider, renderTimeViewClock} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, {Dayjs} from "dayjs";
import {from} from "rxjs";
import {addDoc, collection} from "firebase/firestore";
import {firestoreDb} from "../../config/Firebase";
import {SlideTransition} from "../common/SlideTransition";
import {SxProps} from "@mui/system";

export default function DemoRequest(){

  // State & Refs
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [orgName, setOrgName] = useState("");
  const [phone, setPhone] = useState('');
  const [time, setTime] = useState<Dayjs | null | undefined>(dayjs());
  const [comment, setComment] = useState('');

  const [submitConfirmationOpen, setSubmitConfirmationOpen] = useState(false);

  return <div>
    <Grid2 style={styles.content}>
      <Typography style={styles.titleText}>
        See our app in action
      </Typography>
      <Typography
        sx={sxStyles.subtitleText}
        style={styles.subtitleText}
      >
        Get a hands-on walkthrough tailored to your needs. <br/>Request your demo and explore the possibilities!
      </Typography>
      <form
        id="demo-request-form"
        style={styles.form}
        onSubmit={(event) => onSubmit(event)}
      >
        <TextField
          fullWidth
          required
          id="first-name"
          label="Name"
          name="name"
          sx={sxStyles.inputField}
          value={name}
          onChange={(event) => setName(event.target.value)}
          margin="normal"/>
        <TextField
          fullWidth
          required
          error ={!!email && !isValidEmail(email)}
          id="email"
          label="Email"
          name="email"
          sx={sxStyles.inputField}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          margin="normal"/>
        <MuiTelInput
          label="Phone number"
          fullWidth
          required
          defaultCountry="RO"
          sx={sxStyles.inputField}
          value={phone}
          onChange={(event) => setPhone(event)}
          margin="normal"/>

        <TextField
          fullWidth
          id="role"
          label="Select Your Role"
          name="role"
          sx={sxStyles.inputField}
          value={role}
          onChange={(event) => setRole(event.target.value)}
          margin="normal"
          select
        >
          <MenuItem value={'Administrator'}>Administrator</MenuItem>
          <MenuItem value={'Professor'}>Professor</MenuItem>
          <MenuItem value={'Parent'}>Parent or guardian</MenuItem>
        </TextField>

        { (role === 'Administrator' || role === 'Professor') &&
          <TextField
            fullWidth
            id="organization"
            label="Organization name"
            name="organization"
            sx={sxStyles.inputField}
            value={orgName}
            onChange={(event) => setOrgName(event.target.value)}
            margin="normal"/>
        }

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            sx={{flex: 1}}
            value={time}
            onChange={(newValue) => setTime(newValue)}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }}
            slots={{
              textField: textFieldProps => <TextField
                {...textFieldProps}
                fullWidth
                label="Preferred Date and Time"
                margin="normal"
              />
          }}
          />
        </LocalizationProvider>
        <TextField
          fullWidth
          id="additional-comments"
          label="Additional Comments or Questions"
          name="comments"
          multiline
          rows={4}
          sx={sxStyles.inputField}
          value={comment}
          onChange={(event) => setComment(event.target.value)}
          margin="normal"/>
        <Button
          sx={{marginY: { xs: 3, sm: 5}}}
          style={styles.containedButton}
          type="submit"
          variant="contained"
          color="primary"
          size="large">
          Submit
        </Button>
      </form>
    </Grid2>

    <Dialog
      open={submitConfirmationOpen}
      onClose={() => onSubmitConfirmationClose()}
      TransitionComponent={SlideTransition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <b>Thank you for reaching out!</b>
          <br/><br/>
          We've received your request and will get back to you as soon as possible. We appreciate your interest in KidsLog, looking forward to connecting with you soon!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onSubmitConfirmationClose()}
          style={{color: '#2390F0'}}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  </div>

  function onSubmit(event: any) {
    event.preventDefault();
    if (!isValidEmail(email)) {
      return;
    }
    from(addDoc(collection(firestoreDb, "DemoRequest"),
      { name, email, role, orgName, phone, time: time?.format(), comment }))
      .subscribe(res => {
        setSubmitConfirmationOpen(true);
      });
  }

  function onSubmitConfirmationClose() {
    setSubmitConfirmationOpen(false);
    resetForm();
  }

  function resetForm() {
    setName('');
    setEmail('');
    setRole('');
    setOrgName('');
    setPhone('');
    setTime(dayjs());
    setComment('');
  }

  function isValidEmail(email: string): boolean {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

}

const sxStyles: { [key: string]: SxProps } = {
  subtitleText: {
    marginTop: 2,
    marginBottom: {xs: 3, sm: 5},
  },
  inputField: {
    width: {xs:'80vw', sm: '60vw', md:'40vw'}
  }
}

const styles: {[key: string] : React.CSSProperties} = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '5%',
  },
  titleText: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 35,
    lineHeight: '130%'
  },
  subtitleText: {
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: 20,
    lineHeight: '120%',
    color: '#70767B',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  containedButton: {
    width: 'fit-content',
    backgroundColor: '#2390F0',
    paddingLeft: 25,
    paddingTop: 15,
    paddingRight:25,
    paddingBottom: 15,
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '120%',
    textTransform: 'none',
    borderRadius: 12,
  },
}
