import { combineReducers, createStore } from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'

import auth from './base/auth/AuthReducers';
import profile from './base/profile/ProfileReducers';
import adminUsers from './admin/admin-user/AdminUserReducers';
import adminOrganizations from './admin/admin-organization/AdminOrganizationReducers';
import adminPricingPlans from './admin/admin-pricing/AdminPricingReducers';
import {applyMiddleware} from "@reduxjs/toolkit";
import {thunk} from "redux-thunk";

const appReducer = combineReducers({
  // Base
  baseAuth: auth,
  baseProfile: profile,
  //baseAppInfo: appInfo,

  // Organization
  // organization,
  // orgActivitiesTemplates,
  // orgActivities,
  // orgChildren,
  // orgRooms,
  // orgStaffMembers,
  // orgContactPersons,
  // orgCalendarEvents,
  // orgLocalPersist,

  // Contact
  // contact,
  // cpChildren,
  // cpActivities,
  // cpCalendarEvents,

  // Staff Member
  // staffMember,
  // smOrganizations,
  // smActivities,
  // smRooms,
  // smChildren,
  // smCalendarEvents,
  // smLocalPersist,

  // Admin
  adminUsers,
  adminOrganizations,
  adminPricingPlans,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'USER_LOGOUT') {
    // for all keys defined in your persistConfig(s)
    storage.removeItem('persist:root');
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(
  {key: 'root', storage, whitelist: ['orgLocalPersist', 'smLocalPersist']},
  rootReducer,
);

let store = createStore(persistedReducer, applyMiddleware(thunk));
let persistor = persistStore(store);

export {store, persistor};

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
