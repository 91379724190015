import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid2,
  TextField,
  Typography
} from "@mui/material";
import { CustomerChat } from "../../config/Facebook";
import { from } from "rxjs";
import { firestoreDb } from "../../config/Firebase";
import { addDoc, collection } from 'firebase/firestore';
import {SlideTransition} from "../common/SlideTransition";
import {SxProps} from "@mui/system";

export default function Contact(){

  // State & Refs
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [submitConfirmationOpen, setSubmitConfirmationOpen] = useState(false);


  return <div>
    <Grid2 style={styles.content}>
      <Typography style={styles.titleText}>
        Get in touch
      </Typography>
      <Typography
        sx={sxStyles.subtitleText}
        style={styles.subtitleText}
      >
        We’d love to hear from you. Please fill out this form.
      </Typography>
      <form
        id="contact-form"
        style={styles.form}
        onSubmit={(event) => onSubmit(event)}
      >
        <TextField
          fullWidth
          required
          id="first-name"
          label="Name"
          name="name"
          sx={sxStyles.inputField}
          value={name}
          onChange={(event) => setName(event.target.value)}
          margin="normal"/>
        <TextField
          fullWidth
          required
          error ={!!email && !isValidEmail(email)}
          id="email"
          label="Email"
          name="email"
          sx={sxStyles.inputField}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          margin="normal"/>
        <TextField
          fullWidth
          required
          id="message"
          label="Message"
          name="message"
          sx={sxStyles.inputField}
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          margin="normal"
          multiline
          rows={4}/>
        <Button
          sx={{marginY: { xs: 3, sm: 5}}}
          style={styles.containedButton}
          type="submit"
          variant="contained"
          color="primary"
          size="large">
          Submit
        </Button>
      </form>
    </Grid2>
    <CustomerChat/>

    <Dialog
      open={submitConfirmationOpen}
      onClose={() => onSubmitConfirmationClose()}
      TransitionComponent={SlideTransition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <b>Thank you for reaching out!</b>
          <br/><br/>
          We've received your message and will get back to you as soon as possible. We appreciate your interest in KidsLog, looking forward to connecting with you soon!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onSubmitConfirmationClose()}
          style={{color: '#2390F0'}}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  </div>


  function onSubmit(event: any) {
    event.preventDefault();
    if (!isValidEmail(email)) {
      return;
    }
    from(addDoc(collection(firestoreDb, "ContactUsMessage"), { name, email, message }))
      .subscribe(res => {
        setSubmitConfirmationOpen(true);
      });
  }

  function onSubmitConfirmationClose() {
    setSubmitConfirmationOpen(false);
    resetForm();
  }

  function resetForm() {
    setName('');
    setEmail('');
    setMessage('');
  }

  function isValidEmail(email: string): boolean {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

}

const sxStyles: { [key: string]: SxProps } = {
  subtitleText: {
    marginTop: 2,
    marginBottom: {xs: 3, sm: 5},
  },
  inputField: {
    width: {xs:'80vw', sm: '60vw', md:'40vw'}
  }
}

const styles: {[key: string] : React.CSSProperties} = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '5%',
  },
  titleText: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 35,
    lineHeight: '130%'
  },
  subtitleText: {
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: 20,
    lineHeight: '120%',
    color: '#70767B',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  containedButton: {
    width: 'fit-content',
    backgroundColor: '#2390F0',
    paddingLeft: 25,
    paddingTop: 15,
    paddingRight:25,
    paddingBottom: 15,
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '120%',
    textTransform: 'none',
    borderRadius: 12,
  },
};
