import React from "react";

export default function ChatTextSVG(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="52.08043"
         height="52.78091"
         viewBox="0 0 52.08043 52.78091"
         {...props}>
      <path
        d="M23.93316,46.01712c-6.09255,3.34685-12.68143,5.56915-19.7052,5.22835l.53033,1.28033c3.70258-3.32474,6.41306-7.12115,8.12257-11.80557,.14627-.40082-.16781-.79515-.52383-.92259C4.66157,37.04306,.29677,28.17148,1.71614,20.30391,3.25813,11.75658,10.35638,4.98679,18.51767,2.57839c9.14842-2.6997,19.34683-.31441,25.87421,6.75356,6.02758,6.52678,7.92939,15.92691,4.50225,24.18532-1.96446,4.73377-5.55647,8.60287-10.17126,10.84098-4.56204,2.21254-9.29973,2.39656-14.21179,1.58326-.94429-.15635-1.35038,1.28886-.39876,1.44642,4.72435,.78222,9.2304,.77767,13.75249-1.02556,4.41452-1.76034,8.15018-4.84243,10.66591-8.8806,5.10945-8.20154,4.63303-18.81197-.97747-26.62651C41.6059,2.57155,31.42223-1.27303,21.42646,.37418,12.20812,1.89327,3.73092,8.38886,.87719,17.44763c-2.92902,9.29776,1.6563,20.42284,11.08109,23.79643l-.52383-.92259c-1.61982,4.43862-4.23447,7.99872-7.73681,11.14366-.51065,.45854-.08129,1.25065,.53033,1.28033,7.27745,.35311,14.15066-1.96595,20.46228-5.43314,.84629-.4649,.09032-1.76071-.75708-1.29521Z"
        fill={props.color || "#01010C"}/>
      <path
        d="M14.0109,20.09846l1.05436-2.82127-1.25354,.33095c1.96008,2.06592,4.14894,4.34382,7.03233,5.01008,2.26646,.52371,4.927,.0561,6.43336-1.84386,.35002-.44147,.64793-1.25113,1.12179-1.56631,.94282-.62708,1.61952,.66611,2.07061,1.29124,.68647,.95134,1.55037,1.67033,2.78171,1.67316,2.89583,.00665,4.11053-2.87401,4.81104-5.2116,.2777-.92667-1.16956-1.32264-1.44642-.39876-.3636,1.21333-.7371,2.63637-1.7531,3.48388-.53109,.44302-1.3535,.77979-2.05012,.56776-.68554-.20865-1.0382-.88001-1.42699-1.42376-1.11523-1.55976-2.95493-2.3964-4.36141-.67894-.73582,.89851-1.12889,1.85227-2.25172,2.37071-1.03787,.47921-2.25964,.54615-3.36988,.32308-2.69999-.54248-4.71689-2.74575-6.53055-4.65734-.41748-.44003-1.06526-.17285-1.25354,.33095l-1.05436,2.82127c-.33835,.90537,1.11098,1.29634,1.44642,.39876h0Z"
        fill={props.color || "#01010C"}/>
      <path
        d="M18.63599,29.73096c-.1581-.2279,.3614-.25333,.51011-.18961,.39047,.16729,.68081,.58358,.93097,.90536,.504,.64829,1.04151,1.33647,1.8667,1.57929,1.28601,.37843,2.33879-.45718,3.20416-1.30009,.73433-.71528,1.72365-1.88623,2.62503-.70805,.47438,.62006,.70859,1.38706,1.17993,2.01313,.51994,.69063,1.18383,1.15147,2.06608,1.18869,.96522,.04072,.9633-1.45936,0-1.5-.90185-.03804-1.29492-1.35555-1.66382-2.0025-.43521-.76325-1.04463-1.4817-1.93938-1.68299-1.49116-.33546-2.56196,.91084-3.52923,1.82487-.34984,.33059-.83583,.82324-1.36967,.76445-.86151-.09486-1.39786-1.38863-1.98339-1.91887-.69091-.62567-1.67347-.98777-2.53558-.47891-.77038,.45471-1.20258,1.47607-.65711,2.26233,.54652,.78776,1.84729,.0387,1.29521-.75708h0Z"
        fill={props.color || "#01010C"}/>
    </svg>
  );
}

