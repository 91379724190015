import React from "react";

export default function RocketSVG(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="46.74479"
         height="55.18999"
         viewBox="0 0 46.74479 55.18999"
         {...props}>
      <g>
        <path
          d="M25.21414,41.95628c6.36189-9.71435,12.72379-19.42871,19.08568-29.14306,1.38953-2.12176,2.54428-3.91138,2.43819-6.51288-.05289-1.29705,.08743-2.62068-.21119-3.8987-.67963-2.90864-3.71388-2.61704-6.01774-1.9837-.98061,.26957-2.0259,.43747-2.93569,.90621-.95341,.49121-1.75586,1.2544-2.4908,2.02251-1.92728,2.01427-3.7985,4.08446-5.70867,6.11523-3.89371,4.13953-7.81605,8.252-11.71923,12.38258-2.00782,2.1248-4.01062,4.25436-6.00185,6.39471-1.05901,1.13831-2.04832,2.00755-3.37044,2.83026-1.03607,.64471-2.24051,1.21823-3.14165,2.04845-2.73834,2.52281,2.41555,5.40515,4.12856,6.76566,3.13138,2.48702,6.26932,4.9658,9.39644,7.45818,1.06937,.85231,2.7061,2.72013,4.20547,1.95621,.96874-.49357,1.10256-1.76542,1.31249-2.70022,.16097-.71677,.32194-1.43354,.48291-2.15031,.08943-.39821,.17886-.79641,.26828-1.19462,.05449-.24265,.08783-1.04979,.16195-1.14471,.5873-.75217-.46692-1.82107-1.06066-1.06066-.49225,.63043-.53669,1.75754-.70868,2.52338-.26463,1.17835-.36749,2.63056-.84317,3.73819-.38398,.8941-.69843,.58217-1.27089,.16117-.20433-.15027-.39828-.3196-.59632-.47806-.49665-.39737-.99416-.79368-1.49186-1.18975-2.48833-1.98023-4.98211-3.95361-7.47336-5.93017-1.14643-.90958-2.29274-1.81931-3.43687-2.73177-.38661-.30832-.77428-.61544-1.1587-.9265-.2598-.21023-.58311-.40653-.78653-.67135-.59758-.77795-.20305-1.29362,.49007-1.75941,1.01083-.67929,2.07452-1.2909,3.10854-1.93434,1.08119-.67279,1.8111-1.43685,2.67915-2.37051,1.71415-1.84372,3.43705-3.67926,5.16447-5.51054,3.39813-3.60243,6.81395-7.18812,10.21572-10.78711,1.72912-1.82937,3.4547-3.6621,5.17242-5.50218,1.51065-1.61826,2.99236-3.71636,4.9088-4.87798,.84428-.51174,1.94793-.67467,2.89885-.93613,.76395-.21005,1.55591-.40328,2.35262-.40658,1.0028-.00414,1.59231,.35178,1.81994,1.34262,.25176,1.09587,.12559,2.26096,.15684,3.37466,.03475,1.23842-.04378,2.18694-.63161,3.32305-.57352,1.10845-1.32454,2.13586-2.00709,3.1781-6.22653,9.50767-12.45307,19.01533-18.6796,28.523-.53041,.80991,.76786,1.56232,1.29521,.75708Z"
          fill={props.color || "#01010C"}/>
        <path
          d="M20.3798,18.52696c-4.18141,.14043-8.36283,.28086-12.54424,.42129-1.70775,.05735-3.49169,.0426-4.94653,1.06985-1.25573,.88666-2.05482,2.24559-2.79171,3.56014-.14672,.26173-.14273,.70431,.11727,.90887,2.69652,2.12149,5.39305,4.24298,8.08957,6.36447,.75046,.59042,1.8191-.46395,1.06066-1.06066-2.69652-2.12149-5.39305-4.24298-8.08957-6.36447l.11727,.90887c.69306-1.23637,1.44474-2.64621,2.7482-3.33404,1.51412-.79899,3.60313-.54995,5.26286-.60569,3.65874-.12288,7.31747-.24575,10.97621-.36863,.9627-.03233,.96709-1.53248,0-1.5h0Z"
          fill={props.color || "#01010C"}/>
        <path
          d="M32.13041,29.07033c.74056,3.4515,1.48112,6.90301,2.22168,10.35451,.34745,1.61936,1.03194,3.49244,.84979,5.15906-.18027,1.64938-1.52055,2.86833-2.65754,3.94039h1.06066c-2.71205-2.10161-5.42409-4.20322-8.13614-6.30483-.75385-.58417-1.82302,.46989-1.06066,1.06066,2.71205,2.10161,5.42409,4.20322,8.13614,6.30483,.32797,.25415,.73506,.30701,1.06066,0,1.15699-1.09092,2.34822-2.26931,2.86289-3.81576,.56805-1.70685,.02221-3.51982-.34024-5.2091-.85027-3.96284-1.70054-7.92567-2.55082-11.88851-.20241-.94335-1.64873-.54414-1.44642,.39876h0Z"
          fill={props.color || "#01010C"}/>
        <path
          d="M12.42527,42.37462c-2.56363,.35952-5.34844,.91472-6.89871,3.21414-.78338,1.16194-.861,2.60914-1.0499,3.95551-.21931,1.56312-.43861,3.12625-.65792,4.68937-.07501,.53462,.31749,1.10178,.92259,.92259,1.53899-.45575,3.14827-.19617,4.72588-.29259,1.53584-.09386,3.03716-.56541,4.07437-1.76024,2.34828-2.70515,1.60198-6.368,.75793-9.50988-.25019-.93129-1.69758-.53614-1.44642,.39876,.70514,2.6248,1.61056,6.09384-.59633,8.29002-.9892,.9844-2.37864,1.10316-3.70295,1.10537-1.41432,.00236-2.83715-.08479-4.21124,.32213l.92259,.92259c.19381-1.38136,.38761-2.76273,.58142-4.14409,.17405-1.24059,.17447-2.74244,.79938-3.8571,1.20182-2.14373,3.98705-2.5029,6.17807-2.81016,.40421-.05669,.61741-.56758,.52383-.92259-.11413-.43297-.52337-.57981-.92259-.52383h0Z"
          fill={props.color || "#01010C"}/>
      </g>
      <path
        d="M37.21253,9.43986c.31833,.03122,.63818,.0532,.95537,.09499l-.19938-.02679c.13668,.01854,.27545,.04058,.40379,.09319l-.17916-.07561c.04808,.02055,.09316,.04552,.1349,.07709l-.15179-.11727c.03736,.02939,.07013,.06282,.09961,.10008l-.11727-.15179c.05923,.07741,.10191,.16517,.13992,.25451l-.07561-.17916c.10754,.25703,.1883,.52545,.28075,.78809,.06551,.18609,.1703,.34619,.34467,.44822,.16076,.09408,.39769,.13393,.57792,.07561,.36462-.11799,.6633-.52637,.52383-.92259-.07734-.2197-.14855-.44176-.23016-.65993-.07039-.18816-.14065-.38897-.25971-.55345-.1857-.25654-.39893-.41908-.69429-.53233-.20335-.07798-.4282-.09683-.64202-.12354-.30281-.03782-.60771-.05954-.91137-.08932-.1802-.01767-.40707,.09641-.53033,.21967-.13008,.13008-.22804,.3435-.21967,.53033,.00869,.19397,.0724,.39514,.21967,.53033,.15454,.14186,.32196,.19923,.53033,.21967h0Z"
        fill={props.color || "#01010C"}/>
    </svg>
  );
}

