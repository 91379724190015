import {
  Box,
  Collapse,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery, useTheme
} from "@mui/material";
import React from "react";
import FeatherIcon from "feather-icons-react";
import MenuItems from "./MenuItems";
import {NavLink, useLocation} from "react-router-dom";
import Scrollbar from "../../components/custom-scroll/Scrollbar";
import {SidebarWidth} from "../../themes/Theme-variable";

type Props = {
  isSidebarOpen: boolean,
  isMobileSidebarOpen: boolean,
  onSidebarClose: () => void,
}
export default function Sidebar({ isSidebarOpen, isMobileSidebarOpen, onSidebarClose }: Props) {

  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const { pathname } = useLocation();
  const pathDirect = pathname;
  const pathWithoutLastPart = pathname.slice(0, pathname.lastIndexOf('/'));
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));


  const SidebarContent = (
    <Scrollbar style={{ height: 'calc(100vh - 5px)' }}>
      <Box sx={{ p: 2 }}>
        <Box>
          <List>
            {MenuItems.map((item, index) => {
              // {/********SubHeader**********/}
              if (item.subheader) {
                return (
                  <li key={item.subheader}>
                    <Typography
                      variant="subtitle2"
                      fontWeight="500"
                      sx={{ my: 2, mt: 0, opacity: '0.4' }}
                    >
                      {item.subheader}
                    </Typography>
                  </li>
                );
                // {/********If Sub Menu**********/}
                /* eslint no-else-return: "off" */
              } else if (item.children) {
                return (
                  <React.Fragment key={item.title}>
                    <ListItemButton
                      component="li"
                      onClick={() => handleClick(index)}
                      selected={pathWithoutLastPart === item.href}
                      sx={{
                        mb: 1,
                        ...(pathWithoutLastPart === item.href && {
                          color: 'white',
                          backgroundColor: '#2390F0 !important',
                          borderRadius: 1,
                        }),
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ...(pathWithoutLastPart === item.href && {
                            color: 'white',
                          }),
                        }}
                      >
                        <FeatherIcon icon={item.icon} size="20" />
                      </ListItemIcon>
                      <ListItemText>{item.title}</ListItemText>
                      {Boolean(index) === open || pathWithoutLastPart === item.href ? (
                        <FeatherIcon icon="chevron-down" size="16" />
                      ) : (
                        <FeatherIcon icon="chevron-right" size="16" />
                      )}
                    </ListItemButton>
                    <Collapse in={Boolean(index) === open} timeout="auto" unmountOnExit>
                      <List component="li" disablePadding>
                        {item.children?.map((child) => {
                          return (
                            <ListItemButton
                              key={child.title}
                              component={NavLink}
                              to={child.href}
                              onClick={onSidebarClose}
                              selected={pathDirect === child.href}
                              sx={{
                                mb: 1,
                                ...(pathDirect === child.href && {
                                  color: 'primary.main',
                                  backgroundColor: 'transparent!important',
                                }),
                              }}
                            >
                              <ListItemIcon
                                sx={{
                                  svg: { width: '14px', marginLeft: '3px' },
                                  ...(pathDirect === child.href && {
                                    color: 'primary.main',
                                  }),
                                }}
                              >
                                <FeatherIcon icon={child.icon} size="20" />
                              </ListItemIcon>
                              <ListItemText>{child.title}</ListItemText>
                            </ListItemButton>
                          );
                        })}
                      </List>
                    </Collapse>
                  </React.Fragment>
                );
                // {/********If Sub No Menu**********/}
              } else {
                return (
                  <List component="li" disablePadding key={item.title}>
                    <ListItemButton
                      onClick={() => handleClick(index)}
                      component={NavLink}
                      to={item.href}
                      selected={pathDirect === item.href}
                      sx={{
                        mb: 1,
                        ...(pathDirect === item.href && {
                          color: 'white',
                          backgroundColor: '#2390F0 !important',
                          borderRadius: 1,
                        }),
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ...(pathDirect === item.href && { color: 'white' }),
                        }}
                      >
                        <FeatherIcon icon={item.icon} size="20" />
                      </ListItemIcon>
                      <ListItemText onClick={onSidebarClose}>{item.title}</ListItemText>
                    </ListItemButton>
                  </List>
                );
              }
            })}
          </List>
        </Box>
      </Box>
    </Scrollbar>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open={isSidebarOpen}
        variant="persistent"
        PaperProps={{
          sx: {
            width: SidebarWidth,
            top: lgUp ? '100px' : '0px',
            left: lgUp ? '30px' : '',
            borderRadius: lgUp ? '9px' : '0',
            border: '0',
            height: 'calc(100vh - 130px)',
            boxShadow:'0px 7px 30px 0px rgb(90 114 123 / 11%)'
          },
        }}
      >
        {SidebarContent}
      </Drawer>
    );
  }
  return (
    <Drawer
      anchor="left"
      open={isMobileSidebarOpen}
      onClose={onSidebarClose}
      PaperProps={{
        sx: {
          width: SidebarWidth,
          border: '0 !important',
        },
      }}
      variant="temporary"
    >
      {SidebarContent}
    </Drawer>
  );

  function handleClick(index: any) {
    if (open === index) {
      setOpen((prevopen) => !prevopen);
    } else {
      setOpen(index);
    }
  };
}

Sidebar.propTypes = {
};
