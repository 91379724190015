import {
  AppBar,
  Avatar,
  Box,
  Button,
  Chip,
  IconButton,
  Menu,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery, useTheme
} from "@mui/material";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import logo from "../../components/image/logo.svg";
import FeatherIcon from "feather-icons-react";
import {Profile, profileDisplayName} from "../../domain/common/Profile";
import {logout} from "../../state/base/auth/AuthActions";
import React from "react";
import {SxProps} from "@mui/system";
import {useDispatch, useSelector} from "react-redux";
import {profileCurrentSelector} from "../../state/base/profile/ProfileSelectors";
import {isEqual} from "lodash";
import {Dispatch} from "redux";

type Props = {
  sx?: SxProps<Theme>,
  toggleSidebar: () => void,
  toggleMobileSidebar: () => void,
}
export default function Header({ sx, toggleSidebar, toggleMobileSidebar}: Props) {
  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('sm'));

  // Selectors
  const profile: Profile = useSelector(profileCurrentSelector(), isEqual);

  // State
  const [notificationAnchorEl, setNotificationAnchorEl] = React.useState<null | HTMLElement>(null);
  const [accountAnchorEl, setAccountAnchorEl] = React.useState<null | HTMLElement>(null);

  return (
    <AppBar sx={sx}
            elevation={0}
            style={styles.appBar}>
      <Toolbar sx={{px: {xs:2, sm:5} }}>
        { mdUp &&
          <Box sx={sxStyles.logoBox}>
            <RouterLink to="/" style={styles.logoRouterLink}>
              <img src={logo}
                   style={{height: '2rem'}}
                   alt="logo"/>
              <Typography style={styles.logoText} color="textSecondary" variant="h2" fontWeight="500" sx={{ ml: 1 }}>
                KidsLog
              </Typography>
            </RouterLink>
          </Box>
        }
        {/*<IconButton*/}
        {/*  edge="start"*/}
        {/*  color="inherit"*/}
        {/*  aria-label="menu"*/}
        {/*  onClick={toggleSidebar}*/}
        {/*  size="large"*/}
        {/*  sx={{*/}
        {/*    display: {*/}
        {/*      lg: 'flex',*/}
        {/*      xs: 'none',*/}
        {/*    },*/}
        {/*  }}>*/}
        {/*  <FeatherIcon icon="menu" />*/}
        {/*</IconButton>*/}
        <IconButton
          size="large"
          color="inherit"
          aria-label="menu"
          onClick={toggleMobileSidebar}
          sx={{
            display: {
              lg: 'none',
              xs: 'flex',
            },
          }}
        >
          <FeatherIcon icon="menu" size="20" />
        </IconButton>

        <Box flexGrow={1} />

        {/* ------------------------------------------- */}
        {/* Notifications Dropdown */}
        {/* ------------------------------------------- */}
        {/*<IconButton*/}
        {/*  size="large"*/}
        {/*  aria-label="menu"*/}
        {/*  aria-controls="notification-menu"*/}
        {/*  aria-haspopup="true"*/}
        {/*  onClick={(event) => setNotificationAnchorEl(notificationAnchorEl ? null : event.currentTarget)}*/}
        {/*>*/}
        {/*  <Badge variant="dot" color="secondary">*/}
        {/*    <FeatherIcon icon="bell" size="20" />*/}
        {/*  </Badge>*/}
        {/*</IconButton>*/}
        <Menu
          id="notification-menu"
          anchorEl={notificationAnchorEl}
          keepMounted
          open={Boolean(notificationAnchorEl)}
          onClose={() => setNotificationAnchorEl(null)}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          sx={{
            '& .MuiMenu-paper': {
              width: '385px',
              right: 0,
              top: '70px !important',
            },
            '& .MuiList-padding': {
              p: '30px',
            },
          }}
        >
          <Box
            sx={{
              mb: 1,
            }}
          >
            <Box display="flex" alignItems="center">
              <Typography variant="h4" fontWeight="500">
                Notifications
              </Typography>
              <Box
                sx={{
                  ml: 2,
                }}
              >
                <Chip
                  size="small"
                  label="5 new"
                  sx={{
                    borderRadius: '6px',
                    pl: '5px',
                    pr: '5px',
                    backgroundColor: (theme) => theme.palette.warning.main,
                    color: '#fff',
                  }}
                />
              </Box>
            </Box>
          </Box>
          {/*<NotificationDropdown />*/}
          <Button
            sx={{
              mt: 2,
              display: 'block',
              width: '100%',
            }}
            variant="contained"
            color="primary"
            onClick={() => setNotificationAnchorEl(null)}
          >
            <RouterLink
              to="/email"
              style={{
                color: '#fff',
                width: '100%',
                display: 'block',
                textDecoration: 'none',
              }}
            >
              See all notifications
            </RouterLink>
          </Button>
        </Menu>
        {/* ------------------------------------------- */}
        {/* End Notifications Dropdown */}
        {/* ------------------------------------------- */}


        {/* ------------------------------------------- */}
        {/* Profile Dropdown */}
        {/* ------------------------------------------- */}
        {/*{ profile != null*/}
        {/*  ?*/}
        {/*  <Button size="medium"*/}
        {/*          color="inherit"*/}
        {/*          onClick={(e) => setAccountAnchorEl(accountAnchorEl ? null : e.currentTarget)}*/}
        {/*          aria-controls={!!accountAnchorEl ? 'account-menu' : undefined}*/}
        {/*          aria-haspopup="true"*/}
        {/*          aria-expanded={!!accountAnchorEl ? 'true' : undefined}>*/}
        {/*    <Box display="flex" alignItems="center">*/}
        {/*      <Avatar sx={{ width: 30, height: 30 }}/>*/}

        {/*      <Box*/}
        {/*        sx={{*/}
        {/*          display: {*/}
        {/*            xs: 'none',*/}
        {/*            sm: 'flex',*/}
        {/*          },*/}
        {/*          alignItems: 'center',*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <Typography color="textSecondary" variant="h5" fontWeight="400" sx={{ ml: 1 }}>*/}
        {/*          Hi,*/}
        {/*        </Typography>*/}
        {/*        <Typography*/}
        {/*          variant="h5"*/}
        {/*          fontWeight="700"*/}
        {/*          sx={{*/}
        {/*            ml: 1,*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          {profileDisplayName(profile)}*/}
        {/*        </Typography>*/}
        {/*        <FeatherIcon icon="chevron-down" size="20"/>*/}
        {/*      </Box>*/}
        {/*    </Box>*/}
        {/*    <Menu*/}
        {/*      anchorEl={accountAnchorEl}*/}
        {/*      id="account-menu"*/}
        {/*      keepMounted*/}
        {/*      open={Boolean(accountAnchorEl)}*/}
        {/*      onClose={() => setAccountAnchorEl(null)}*/}
        {/*      onClick={() => setAccountAnchorEl(null)}*/}
        {/*      sx={{*/}
        {/*        '& .MuiMenu-paper': {*/}
        {/*          width: '385px',*/}
        {/*          right: 0,*/}
        {/*          top: '70px !important',*/}
        {/*        },*/}
        {/*        '& .MuiList-padding': {*/}
        {/*          p: '30px',*/}
        {/*        },*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <Box*/}
        {/*        sx={{*/}
        {/*          mb: 1,*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <Box display="flex" alignItems="center">*/}
        {/*          <Typography variant="h4" fontWeight="500">*/}
        {/*            User Profile*/}
        {/*          </Typography>*/}
        {/*        </Box>*/}
        {/*      </Box>*/}
        {/*      <Box display="flex" alignItems="center"*/}
        {/*           sx={{*/}
        {/*             pb: 3,*/}
        {/*             mt: 3,*/}
        {/*           }}>*/}
        {/*        <Avatar*/}
        {/*          sx={{*/}
        {/*            width: '70px',*/}
        {/*            height: '70px',*/}
        {/*          }}*/}
        {/*        />*/}
        {/*        <Box*/}
        {/*          sx={{*/}
        {/*            ml: 2,*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          <Typography*/}
        {/*            variant="h4"*/}
        {/*            sx={{*/}
        {/*              lineHeight: '1.235',*/}
        {/*            }}*/}
        {/*          >*/}
        {/*            { Boolean(profile.firstName) && `${profile.firstName} ` }*/}
        {/*            { Boolean(profile.lastName) && `${profile.lastName}` }*/}
        {/*          </Typography>*/}
        {/*          <Typography color="textSecondary" variant="h6" fontWeight="400">*/}
        {/*            { profile.type }*/}
        {/*          </Typography>*/}
        {/*          <Box display="flex" alignItems="center">*/}
        {/*            <Typography*/}
        {/*              color="textSecondary"*/}
        {/*              display="flex"*/}
        {/*              alignItems="center"*/}
        {/*              sx={{*/}
        {/*                color: (theme) => theme.palette.grey.A200,*/}
        {/*                mr: 1,*/}
        {/*              }}*/}
        {/*            >*/}
        {/*              <FeatherIcon icon="mail" size="18" />*/}
        {/*            </Typography>*/}
        {/*            <Typography color="textSecondary" variant="h6">*/}
        {/*              { profile.email }*/}
        {/*            </Typography>*/}
        {/*          </Box>*/}
        {/*        </Box>*/}
        {/*      </Box>*/}
        {/*      <Button*/}
        {/*        sx={{*/}
        {/*          mt: 2,*/}
        {/*          display: 'block',*/}
        {/*          width: '100%',*/}
        {/*        }}*/}
        {/*        variant="contained"*/}
        {/*        color="primary"*/}
        {/*        onClick={() => {*/}
        {/*          dispatch(logout(() => navigate('/auth/login')));*/}
        {/*          setAccountAnchorEl(null);*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        Logout*/}
        {/*      </Button>*/}
        {/*    </Menu>*/}

        {/*  </Button>*/}
        {/*  :*/}
        {/*  <RouterLink to="/auth/login" style={styles.logoRouterLink}>*/}
        {/*    <Typography color="textSecondary" variant="h5" fontWeight="700" sx={{ ml: 1 }}>*/}
        {/*      Login*/}
        {/*    </Typography>*/}
        {/*  </RouterLink>*/}
        {/*}*/}
      </Toolbar>
    </AppBar>
  );

}


const sxStyles: { [key: string]: SxProps } = {
  logoBox: {
    display: 'flex',
    justifyContent: {xs: 'center', sm: 'left'},
    width:  '250px',
  },
}

const styles: { [key: string]: React.CSSProperties } = {
  logoRouterLink: {
    display: 'flex',
    height: '100%',
    flexDirection: 'row',
    textDecoration: 'none',
    alignItems: 'center',
    color: '#000',
  },
  appBar: {
    backgroundColor: '#ffffff',
    boxShadow:'0px 7px 30px 0px rgb(90 114 123 / 11%)',
  },
  logoText: {
    fontFamily: 'Clash Grotesk',
    color: '#2B353F',
    marginLeft: 10,
  },
}
