import {Organization} from "../../../domain/common/Organization";
import {OrgPricingPlan} from "../../../domain/organization/OrgPricingPlan";

export const SERVER_ADMIN_ORGANIZATIONS_SET = 'admin/server/admin-organizations/set';
export const SERVER_ADMIN_ORGANIZATION_PRICING_PLANS_SET = 'admin/server/admin-organizations/pricingPlans/set';

export const initialState: {
  server: {
    organizations: { [id: string]: Organization },
    pricingPlans: { [id: string]: OrgPricingPlan },
  },
  local: {}
} = {
  server: {
    organizations: {},
    pricingPlans: {},
  },
  local: {}
};

const AdminOrganizationReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case SERVER_ADMIN_ORGANIZATIONS_SET:
      return {
        ...state,
        server: {
          ...state.server,
          organizations: action.organizations
        }
      };
    case SERVER_ADMIN_ORGANIZATION_PRICING_PLANS_SET:
      return {
        ...state,
        server: {
          ...state.server,
          pricingPlans: action.pricingPlans
        }
      };
  default:
    return state;
  }
};

export default AdminOrganizationReducers;
