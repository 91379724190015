import {
  PROFILE_SERVER_ADD_PROFILE, PROFILE_SERVER_REMOVE_PROFILE, PROFILE_SERVER_SET_CURRENT_USER_ROLES,
} from './ProfileReducers';
import {
  deleteProfile,
  getProfile, getUserRoles,
} from '../../../domain/common/repo/ProfileRepo';
import {FetchStatus} from '../../../domain/common/event/EntityEvent';
import {Dispatch} from "redux";

export function loadProfile(userId: string, forceRefresh = false) {
  return (dispatch: any, getState: any)  => {
    if (!userId) {
      return;
    }
    if (!forceRefresh && userId in getState().baseProfile.server) {
      return;
    }

    dispatch({type: PROFILE_SERVER_ADD_PROFILE, id: userId, profile: {status: FetchStatus.FETCHING}});
    getProfile(userId,
      (profile) => {
        dispatch({type: PROFILE_SERVER_ADD_PROFILE, id: userId, profile: {entity: profile, status: FetchStatus.OK}});
      },
      (error) => {
        const status = error?.response?.status === 404 ? FetchStatus.NOT_FOUND : FetchStatus.ERROR;
        dispatch({type: PROFILE_SERVER_ADD_PROFILE, id: userId, profile: { status }});
      });

  };
}

export function loadUserRoles() {
  return (dispatch: any)  => {
    getUserRoles((roles) =>
      dispatch({type: PROFILE_SERVER_SET_CURRENT_USER_ROLES, roles}));
  };
}

export function deleteProfileAction(id: string,
                                    onProfileDeleted: (userId: string) => void) {
  return (dispatch: Dispatch<any>) =>
    deleteProfile(id, () => {
      dispatch({type: PROFILE_SERVER_REMOVE_PROFILE, id});
      onProfileDeleted(id);
    });
}
