import {Profile, ProfileType} from "../Profile";
import {doDelete, doGet, doPost, doPut} from "./AxiosKLBackend";
import {catchError, Observable, of} from "rxjs";
import {UserRole} from "../AdminUser";


export function createProfile(userId: string,
                              profile: Profile,
                              onCreated: (profile: Profile) => void) {
  doPost(`profiles/${userId}`, {}, profile)
    .subscribe({
      next: (response: Profile) => onCreated(response),
      error: (e) => console.log(e)});
}

export function getProfile(userId: string,
                           onFetched: (profile: Profile) => void,
                           onError?: (e: any) => void) {
  doGet(`profiles/${userId}`, {})
    .subscribe({
      next: (response: Profile) => onFetched(response),
      error: (e) => {
        console.log(e);
        if (onError) {
          onError(e)
        }
      }});
}

export function getUserRoles(onFetched: (roles: UserRole[]) => void,
                             onError?: (e: any) => void) {
  doGet(`profiles/roles`, {})
    .subscribe({
      next: (response: UserRole[]) => onFetched(response),
      error: (e) => {
        console.log(e);
        if (onError) {
          onError(e)
        }
      }});
}

export function findProfiles$(email: string,
                              type: ProfileType): Observable<Profile[]> {
    return doGet(`profiles`, {type, email})
        .pipe(catchError((err: any) => {
            console.log(err);
            return of([]);
        }));
}

export function updateProfile(userId: string,
                              profile: Profile,
                              onUpdated: (userId: string, profile: Profile) => void) {
  doPut(`profiles/${userId}`, {}, profile)
    .subscribe({
      next: (response: Profile) => onUpdated(userId, response),
      error: (e) => console.log(e)});
}

export function removeProfilePhoto(userId: string,
                                   onRemoved: (profile: Profile) => void) {
  doPost(`profiles/${userId}/removePhoto`, {}, {})
    .subscribe({
      next: (response: Profile) => onRemoved(response),
      error: (e) => console.log(e)});
}

export function deleteProfile(userId: string,
                              onDelete: () => void) {
  doDelete(`profiles/${userId}`, {})
    .subscribe({
      next: () => onDelete(),
      error: (e) => console.log(e)});
}
