import axios, {AxiosHeaders, AxiosRequestConfig, AxiosResponse} from 'axios';
import {from, switchMap} from 'rxjs';
import {map} from 'rxjs/operators';
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "../../../config/Firebase";

const config: AxiosRequestConfig = {
  baseURL: '/api/v1/',
  timeout: 5000,
  headers: {common: {} as AxiosHeaders}
};

onAuthStateChanged(auth, user => {
  user?.getIdToken(false)
    .then((token) => {
      config.headers = {
        authorization: token
      };
    })
    .catch(err => {
      console.log(err);
    });
});

export function doGet(url: string, queryParams: any) {
  return from(auth.currentUser?.getIdToken(false)!)
    .pipe(
      switchMap(token => {
        config.headers = { Authorization: `Bearer ${token}` };
        config.params = queryParams;

        return from(axios.get(url, config));
      }),
      map((response: AxiosResponse) => response.data));
}

export function doPost(url: string,
  queryParams: any,
  requestBody: any,
  contentType?: string) {
  return from(auth.currentUser?.getIdToken(false)!)
    .pipe(
      switchMap(token => {
        config.headers = { Authorization: `Bearer ${token}`, 'Content-Type': contentType || 'application/json' };
        config.params = queryParams;

        return from(axios.post(url, requestBody, config));
      }),
      map((response: AxiosResponse) => response.data));
}

export function doPut(url: string,
  queryParams: any,
  requestBody: any,
  contentType?: string) {
  return from(auth.currentUser?.getIdToken(false)!)
    .pipe(
      switchMap(token => {
        config.headers = { Authorization: `Bearer ${token}`, 'Content-Type': contentType || 'application/json' };
        config.params = queryParams;

        return from(axios.put(url, requestBody, config));
      }),
      map((response: AxiosResponse) => response.data));
}

export function doPatch(url: string,
  queryParams: any,
  requestBody: any,
  contentType?: string) {
  return from(auth.currentUser?.getIdToken(false)!)
    .pipe(
      switchMap(token => {
        config.headers = { Authorization: `Bearer ${token}`, 'Content-Type': contentType || 'application/json' };
        config.params = queryParams;

        return from(axios.patch(url, requestBody, config));
      }),
      map((response: AxiosResponse) => response.data));
}

export function doDelete(url: string,
  queryParams: any) {
  return from(auth.currentUser?.getIdToken(false)!)
    .pipe(
      switchMap(token => {
        config.headers = { Authorization: `Bearer ${token}` };
        config.params = queryParams;

        return from(axios.delete(url, config));
      }),
      map((response: AxiosResponse) => response.data));
}
