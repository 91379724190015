import {AuthContextProvider} from "./views/auth/AuthContextProvider";
import React from "react";
import AppRoutes from "./routes/AppRoutes";
import {BrowserRouter} from "react-router-dom";
import {CssBaseline, ThemeProvider} from "@mui/material";
import ThemeSettings from "./themes/ThemeSettings";
import {ability, AbilityContext} from "./Ability";


export default function App() {
  const themeSettings = ThemeSettings();


  return (
    <AbilityContext.Provider value={ability}>
    <BrowserRouter>
      <AuthContextProvider>
        <ThemeProvider theme={themeSettings}>
          <CssBaseline enableColorScheme/>
          <AppRoutes/>
        </ThemeProvider>
      </AuthContextProvider>
    </BrowserRouter>
    </AbilityContext.Provider>
  );
}