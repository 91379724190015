import {Profile} from "./Profile";

export interface AdminUser {
  profile: Profile;
  roles: UserRole[];
}

export enum UserRole {
  SUPER_ADMIN='SUPER_ADMIN',
  ADMIN='ADMIN',
  USER='USER',
}