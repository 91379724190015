import React from 'react';
import {Box, Button, Card, CardContent, Grid2, Typography} from "@mui/material";
import {SxProps} from "@mui/system";
import FunUnderlineSVG from "../../components/image/svg/FunUnderlineSVG";
import PlayTimeSVG from "../../components/image/svg/PlayTimeSVG";
import FunArrowSVG from "../../components/image/svg/FunArrowSVG";
import ArrowSVG from "../../components/image/svg/ArrowSVG";
import ConnectingTeamsSVG from "../../components/image/svg/ConnectingTeamsSVG";
import PersonalNotebookSVG from "../../components/image/svg/PersonalNotebookSVG";
import {useNavigate} from "react-router-dom";
import ChatTextSVG from "../../components/image/svg/ChatTextSVG";
import RocketSVG from "../../components/image/svg/RocketSVG";
import StickyNoteSVG from "../../components/image/svg/StickyNoteSVG";


export default function About() {
  const navigate = useNavigate();

  return <Box>
    <Box sx={sxStyles.content}>
      <Box sx={sxStyles.titleContainer}>
        <Box sx={sxStyles.titleContent}>
          <Box sx={{ display: 'flex' }}>
            <Typography sx={sxStyles.titleText}>
              Meet
            </Typography>
            <Typography sx={sxStyles.titleMainText}>
              KidsLog
            </Typography>
          </Box>
          <Box sx={{display: {xs: 'none', md: 'flex'}}}>
            <FunUnderlineSVG color='#2390F0' width='155' height='60'/>
          </Box>
        </Box>
      </Box>

      <Grid2 container sx={{marginX: '8vw'}}>
        <Grid2 size={{xs: 12, md: 6}}>
          <Card sx={{...sxStyles.raisedCard, marginBottom: {xs: 2, md: 10}}} raised>
            <CardContent sx={sxStyles.cardContent}>
              <Box sx={sxStyles.cardTitleContainer}>
                <Box sx={sxStyles.cardTitleImage}>
                  <PlayTimeSVG color='#2390F0' height='200' width='200'/>
                </Box>
                <Typography sx={sxStyles.cardTitleText}>
                  Our story
                </Typography>
              </Box>
              <Typography sx={sxStyles.cardContentText}>
                KidsLog was born from a shared experience that every parent and teacher faces: staying in touch.
                <br/><br/>
                When our founder noticed that keeping track of a child’s daily experiences across
                multiple environments was challenging, each organization had its own system, making it hard for parents
                to stay involved and updated. We wanted a solution that centered around the child.
                <br/><br/>
                KidsLog was built with flexibility at its core, allowing parents to see updates from any activity center
                where their child spends time, creating a holistic view of their journey through early childhood.
              </Typography>
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 size={6} sx={{display: {xs: 'none', md: 'flex'}, position: 'relative'}}>
          <Box sx={sxStyles.centerAbsoluteLayout}>
            <StickyNoteSVG color='#F53F3B' height='75' width='75'/>
          </Box>
          <FunArrowSVG color='#2390F0' height='150' width='250' style={styles.ourStoryArrow}/>
        </Grid2>
      </Grid2>

      <Grid2 container sx={{marginX: '8vw', marginBottom: {xs: 0, md: 10},}}>
        <Grid2 size={6} sx={{...sxStyles.centerLayout, display: {xs: 'none', md: 'flex'} }}>
          <ChatTextSVG color='#FEC72A' height='75' width='75'/>
        </Grid2>
        <Grid2 size={{xs: 12, md: 6}}>
          <Card sx={sxStyles.raisedCard} raised>
            <CardContent sx={sxStyles.cardContent}>
              <Box sx={sxStyles.cardTitleContainer}>
                <Box sx={sxStyles.cardTitleImage}>
                  <ConnectingTeamsSVG color='#2390F0' height='200' width='200'/>
                </Box>
                <Typography sx={sxStyles.cardTitleText}>
                  Our mission
                </Typography>
              </Box>
              <Typography sx={sxStyles.cardContentText}>
                At KidsLog, we believe in the power of connection to enrich the lives of young learners.
                Our mission is to create a safe, joyful, and engaging space where teachers and parents come
                together to nurture each child’s potential.
                <br/><br/>
                By making communication easy and accessible, we’re supporting parents and educators in creating a bright
                future for every child.
              </Typography>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>

      <Grid2 container sx={{marginX: '8vw'}}>
        <Grid2 size={{xs: 12, md: 6}}>
          <Card sx={sxStyles.raisedCard} raised>
            <CardContent sx={sxStyles.cardContent}>
              <Box sx={sxStyles.cardTitleContainer}>
                <Box sx={sxStyles.cardTitleImage}>
                  <PersonalNotebookSVG color='#2390F0' height='150' width='200'/>
                </Box>
                <Typography sx={sxStyles.cardTitleText}>
                  Our vision
                </Typography>
              </Box>
              <Typography sx={sxStyles.cardContentText}>
                We see a world where every child’s unique journey is supported through strong parent-teacher
                partnerships.
                <br/><br/>
                Together, we can create an environment where children flourish, parents feel engaged, and teachers are
                empowered.
              </Typography>
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 size={6} sx={{display: {xs: 'none', md: 'flex'}, position: 'relative'}}>
          <Box sx={sxStyles.centerAbsoluteLayout}>
            <RocketSVG color='#6c63ff' height='75' width='75'/>
          </Box>
          <ArrowSVG color='#2390F0' width='250' height='80' style={{transform: 'rotate(135deg)'}}/>
        </Grid2>
      </Grid2>
    </Box>

    <Box sx={sxStyles.contactUsBox}>
      <Box sx={{flex: .5}}>
        <Typography sx={sxStyles.contactUsDescription}>
          We’d love to hear from you! Reach out and let us know how we can make your KidsLog experience even better.
        </Typography>
      </Box>
      <Box sx={sxStyles.contactUsBtnBox}>
        <Button sx={sxStyles.outlinedButton}
                disableElevation={true}
                variant="outlined"
                key={'item'}
                onClick={() => {
                  navigate("/contact");
                  window.scrollTo(0, 0);
                }}>
          Contact Us
        </Button>
      </Box>
    </Box>
  </Box>
}

const sxStyles: { [key: string]: SxProps } = {
  content: {
    paddingTop: '3%',
    paddingBottom: '5%',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    marginBottom: {xs: 5, md: 10},
  },
  titleText: {
    fontFamily: 'Poppins',
    fontSize: 35,
  },
  titleMainText: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    marginRight: 1,
    marginLeft: 2,
    fontSize: 35,
  },
  raisedCard: {
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    marginX: '2%',
    marginY: '5%',
  },
  cardTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 5,
  },
  cardTitleImage: {
    display: {xs: 'none', md: 'none', lg: 'flex'},
    marginRight: 5,
  },
  cardTitleText: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: {xs: 23, md: 30, lg: 40},
    lineHeight: '128.91%',
    textAlign: 'center',
    color: '#808080',
  },
  cardContentText: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: 17,
    lineHeight: '130%'
  },
  centerAbsoluteLayout: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  centerLayout: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  contactUsBox: {
    backgroundColor: '#FAFAFB',
    display: 'flex',
    flexDirection: {xs: 'column', md: 'row'},
    alignItems: 'center',
    paddingTop: 5,
    paddingLeft: '6%',
    paddingRight: '6%',
  },
  contactUsDescription: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: {xs: 15, md: 18},
    lineHeight: '130%',
  },
  contactUsBtnBox: {
    flex: .5,
    display: 'flex',
    justifyContent: 'end',
    marginTop: {xs: 5, md: 0},
    marginBottom: {xs: 5, md: 0},
  },
  outlinedButton: {
    width: 'fit-content',
    borderColor: '#2390F0',
    color: '#2390F0',
    paddingX: 2.5,
    paddingY: 1.5,
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '120%',
    textTransform: 'none',
    borderRadius: 2,
  },
}

const styles: { [key: string]: React.CSSProperties } = {
  ourStoryArrow: {
    position: 'absolute',
    bottom: 0,
    transform: 'scaleY(-1)'
  },
}
