import {omit} from 'lodash';
import {Profile} from '../../../domain/common/Profile';
import {EntityEvent, FetchStatus} from '../../../domain/common/event/EntityEvent';
import {UserRole} from "../../../domain/common/AdminUser";

export const PROFILE_SERVER_ADD_PROFILE = 'base/profile/server/profiles/add';
export const PROFILE_SERVER_UPDATE_PROFILE_ENTITY = 'base/profile/server/profiles/updateEntity';
export const PROFILE_SERVER_REMOVE_PROFILE = 'base/profile/server/profiles/remove';
export const PROFILE_SERVER_SET_CURRENT_USER_ROLES = 'base/profile/server/currentUserRoles/set';

// local

export const PROFILE_LOCAL_ADD_SEARCHED = 'base/profile/local/addSearched';

export const initialState: {
  server: {
    profiles: { [id: string]: EntityEvent<Profile, FetchStatus> },
    currentUserRoles: UserRole[];
  },
  local: {
    searched: { [searchTerm: string]: string }
  }
} = {
  server: {
    profiles: {},
    currentUserRoles: [],
  },
  local: {
    searched: {}
  }
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case PROFILE_SERVER_ADD_PROFILE:
      return {
        ...state,
        server: {
          ...state.server,
          profiles: {
            ...state.server.profiles,
            [action.id]: action.profile
          }}
      };
    case PROFILE_SERVER_UPDATE_PROFILE_ENTITY:
      return {
        ...state,
        server: {
          ...state.server,
          profiles: {
            ...state.server.profiles,
            [action.id]: {
              ...state.server.profiles[action.id],
              entity: {
                ...state.server.profiles[action.id].entity,
                ...action.profile
              }
            }
          }
        }
      };
    case PROFILE_SERVER_REMOVE_PROFILE:
      return {
        ...state,
        server: {
          ...state.server,
          profiles: omit(state.server.profiles, action.id)
        }
      };
    case PROFILE_SERVER_SET_CURRENT_USER_ROLES:
      return {
        ...state,
        server: {
          ...state.server,
          currentUserRoles: action.roles
        }
      };

    // local

    case PROFILE_LOCAL_ADD_SEARCHED:
      return {
        ...state,
        local: {
          ...state.local,
          searched: {
            ...state.local.searched,
            [action.searchTerm]: action.profileId
          }
        }
      };
    default:
      return state;
    }
};
