import {createContext} from "react";
import {createContextualCan} from "@casl/react";
import {AbilityBuilder, createMongoAbility, MongoAbility} from "@casl/ability";
import {UserRole} from "./domain/common/AdminUser";

export enum ActionType {
  manage = 'manage',
  create = 'create',
  read = 'read',
  update = 'update',
  delete = 'delete',
}
export enum SubjectType {
  Admin_AdminUser = 'Admin_AdminUser',
  Admin_Organization = 'Admin_Organization',
  Admin_PricingPlan = 'Admin_PricingPlan',
  all = 'all',
}

type Actions = keyof typeof ActionType;
type Subjects = keyof typeof SubjectType;
type AppAbility = MongoAbility<[Actions, Subjects]>

export const ability: AppAbility = createAbility();
export const AbilityContext = createContext<AppAbility>(ability);
export const Can = createContextualCan<AppAbility>(AbilityContext.Consumer);


function createAbility(): AppAbility {
  const abilityBuilder = new AbilityBuilder<AppAbility>(createMongoAbility);
  setDefaultRules(abilityBuilder);

  return abilityBuilder.build();
}

function setDefaultRules(abilityBuilder: AbilityBuilder<AppAbility>) {
  abilityBuilder.cannot([ActionType.manage], SubjectType.Admin_AdminUser);
  abilityBuilder.cannot([ActionType.manage], SubjectType.Admin_Organization);
  abilityBuilder.cannot([ActionType.manage], SubjectType.Admin_PricingPlan);
}

export function updateAbility(userRoles: UserRole[]) {
  const updateAbilityBuilder = new AbilityBuilder<AppAbility>(createMongoAbility);

  if (userRoles?.includes(UserRole.SUPER_ADMIN)) {
    updateAbilityBuilder.can([ActionType.manage], SubjectType.Admin_AdminUser);
    updateAbilityBuilder.can([ActionType.manage], SubjectType.Admin_Organization);
    updateAbilityBuilder.can([ActionType.manage], SubjectType.Admin_PricingPlan);
  } else if (userRoles?.includes(UserRole.ADMIN)) {
    updateAbilityBuilder.cannot([ActionType.manage], SubjectType.Admin_AdminUser);
    updateAbilityBuilder.can([ActionType.manage], SubjectType.Admin_Organization);
    updateAbilityBuilder.can([ActionType.manage], SubjectType.Admin_PricingPlan);
  } else {
    setDefaultRules(updateAbilityBuilder);
  }

  console.log('updateAbilityBuilder.rules', updateAbilityBuilder.rules)

  ability.update(updateAbilityBuilder.rules);
}
