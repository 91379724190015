import React from "react";

export default function PlayTimeSVG(props: any) {
  return (
    <svg width="1107"
         height="1000"
         viewBox="0 0 2214 2000"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
         {...props}>
        <g clip-path="url(#clip0_3_2)">
            <path d="M2213.55 0H0V2000H2213.55V0Z" fill="white"/>
            <path
              d="M375.387 1862.7C375.387 1862.7 104.373 1313.2 249.422 884.069C687.93 -413.268 2039.49 257.989 1913.16 966.679C1843.69 1356.42 2101.77 1473.2 1967.46 1863.53L375.387 1862.7Z"
              fill="#EBF3FA"/>
            <path
              d="M1048.46 1069.05L1068.16 1075.45C1068.16 1075.45 1099.46 1065.09 1126.83 1101.79C1126.83 1101.79 1135.4 1115.45 1124.85 1125.86C1115.14 1135.44 1113.24 1112.86 1094.54 1117.37C1075.84 1121.87 1063.18 1114.84 1054.83 1103.42L1041.06 1095.19L1048.46 1069.05Z"
              fill="#FFB27D"/>
            <path
              d="M1452.84 447.853C1452.96 444.399 1455.64 407.182 1512.32 405.237C1512.32 405.237 1484.45 408.561 1479.63 420.816C1479.63 420.816 1498.61 405.673 1514.07 410.544C1529.53 415.415 1538.61 442.496 1538.7 451.403C1538.79 460.311 1530.2 489.025 1520.39 491.466C1516.68 492.391 1512.4 492.143 1508.51 491.48C1511.12 495.556 1513.68 500.052 1516.12 505.072C1538.74 551.506 1515.41 596.885 1496.27 591.871C1483.98 588.656 1480.51 609.612 1480.51 609.612L1361.19 513.014C1361.19 513.016 1405.02 422.331 1452.84 447.853Z"
              fill="rgb(114 52 15)"/>
            <path
              d="M1160.06 979.91C1160.06 979.91 1136.41 975.299 1131.01 979.91C1125.6 984.521 1099.96 1010.57 1105.45 1019.13C1110.94 1027.68 1137.26 1012.04 1142.74 1010.57C1148.21 1009.1 1163.51 1003.12 1163.51 1003.12L1160.06 979.91Z"
              fill="#FFB27D"/>
            <path
              d="M1411.29 1815.8L1436.64 1825.24C1436.64 1825.24 1546.72 1672.56 1560.28 1538.23C1562.7 1514.25 1561.89 1491.01 1556.65 1469.55C1556.65 1469.55 1557.73 1463.41 1559.61 1452.54C1560.48 1447.47 1561.56 1441.37 1562.78 1434.37C1566.36 1413.87 1571.28 1385.81 1576.91 1353.86C1589.86 1280.34 1606.65 1186.36 1619.68 1117.27L1612.85 1115.08L1538.96 1091.24L1523.69 1042.74L1468.67 1028.25C1468.67 1028.25 1456.38 1052.3 1445.39 1165.88C1443.36 1180.77 1441.52 1197.21 1440.14 1215.02C1435.85 1269.4 1435.54 1336.86 1445.37 1413.97C1447.9 1433.71 1451.08 1454.1 1455.04 1475.04C1457.93 1490.39 1458.37 1511.31 1457.15 1535.33C1451.6 1645.14 1411.29 1815.8 1411.29 1815.8Z"
              fill="#2B478B"/>
            <path
              d="M1440.14 1215.02L1559.61 1452.54C1560.48 1447.47 1561.56 1441.37 1562.78 1434.37C1566.36 1413.87 1571.28 1385.81 1576.91 1353.86C1589.86 1280.34 1606.65 1186.36 1619.68 1117.27L1612.85 1115.08L1538.96 1091.24L1523.69 1042.74L1468.67 1028.25C1468.67 1028.25 1456.38 1052.3 1445.39 1165.88C1443.36 1180.77 1441.52 1197.2 1440.14 1215.02Z"
              fill="#233862"/>
            <path
              d="M1492.7 1058.73L1495.46 1035.02C1541.8 1047.32 1665.61 1000.63 1665.61 1000.63C1665.61 1000.63 1710.5 1040.09 1704.6 1134.67C1701.73 1180.84 1696.47 1377.01 1690.98 1435.06C1689 1456.1 1687.26 1471.08 1686.55 1477.06C1686.54 1477.09 1686.54 1477.1 1686.54 1477.11C1686.32 1478.94 1686.96 1480.66 1687.89 1482.25C1695.96 1495.97 1702.65 1510.24 1708.14 1524.85C1762.01 1668.4 1699.85 1845.17 1699.85 1845.17L1683.71 1838.67C1683.71 1838.67 1648.34 1641.94 1619.6 1555.03C1614.72 1540.29 1610.04 1528.7 1605.75 1521.78C1593.26 1501.61 1581.66 1468.66 1571.13 1429.46C1551.24 1355.35 1535.22 1258.94 1524.56 1184.53C1523.09 1184.6 1492.7 1058.73 1492.7 1058.73Z"
              fill="#2B478B"/>
            <path
              d="M1160.06 979.91L1163.51 1003.12C1163.51 1003.12 1165.04 1003.12 1167.73 1002.93C1176.17 1002.35 1196.69 1001.2 1219.9 998.706C1254.81 995.255 1296.24 989.116 1312.73 978.953C1337.86 963.61 1379.1 916.617 1405.95 869.624C1406.72 868.474 1407.29 867.325 1407.87 866.173C1421.87 841.238 1431.65 816.495 1432.42 796.741C1432.99 784.657 1431.65 774.299 1429.16 765.285C1425.9 753.393 1420.53 743.995 1414.96 736.899C1414.2 735.747 1413.43 734.597 1412.28 733.637C1403.07 722.706 1394.06 718.102 1394.06 718.102C1389.45 716.182 1384.47 718.486 1379.29 724.046V724.238C1375.07 728.652 1370.47 735.362 1366.06 743.802C1365.86 743.995 1365.67 744.187 1365.67 744.379C1364.71 746.105 1363.75 748.022 1362.6 750.131C1362.22 750.515 1362.03 751.091 1361.83 751.475C1359.72 755.886 1357.62 760.489 1355.5 765.284C1331.91 816.686 1311.71 892.226 1290.8 915.433C1281.79 925.406 1235.63 947.303 1202.83 961.88C1179.24 972.24 1160.06 979.91 1160.06 979.91Z"
              fill="#FFB27D"/>
            <path
              d="M1201.87 929.768C1229.38 917.268 1260.97 927.729 1273.47 980.268C1281.81 1015.34 1362.97 1054.27 1326.43 1107.81C1291.47 1149.86 1207.38 1122.34 1214.47 1069.05C1221.56 1015.77 1239.45 971.214 1219.94 962.714C1195.58 952.101 1201.87 929.768 1201.87 929.768Z"
              fill="rgb(114 52 15)"/>
            <path
              d="M1154.36 1083.82C1212.06 1075.57 1267.39 1077.56 1270.47 1141.06C1273.56 1204.56 1288.56 1324.01 1270.56 1347.53C1252.56 1371.05 1158.2 1367.7 1158.2 1367.7C1095.07 1260.06 1154.36 1083.82 1154.36 1083.82Z"
              fill="#FFB93E"/>
            <path
              d="M1404.18 771.054C1418.72 777.799 1436.29 782.393 1457.54 783.552C1537.2 787.917 1555.29 718.082 1558.58 671.732C1547.09 667.573 1537.04 667.966 1531.24 668.277C1525.6 668.588 1519.7 669.167 1514.84 669.767C1505.09 664.676 1487.58 639.949 1477.47 598.586L1474.03 600.179L1416.55 627.203C1416.55 627.203 1422.65 645.909 1427.17 663.807C1430.68 677.815 1433.21 691.348 1431.1 695.135C1426.56 696.977 1423.85 698.135 1423.85 698.135C1423.85 698.135 1423.85 698.156 1423.83 698.197C1423.25 699.667 1410.76 731.511 1404.18 771.054Z"
              fill="#FFB27D"/>
            <path
              d="M1416.55 627.215C1416.55 627.215 1422.66 645.903 1427.16 663.819C1460.76 654.444 1470.97 619.126 1474.04 600.192L1416.55 627.215Z"
              fill="#ED985F"/>
            <path
              d="M1409.47 647.002C1409.47 647.002 1489.86 628.398 1477.5 578.516C1465.15 528.634 1467.2 492.639 1415.35 502.408C1363.51 512.177 1358.29 533.672 1358.36 551.324C1358.44 568.977 1388.24 650.556 1409.47 647.002Z"
              fill="#FFB27D"/>
            <path
              d="M1353.84 523.005C1362.48 504.926 1372.65 505.89 1379.02 509.095C1416.79 474.148 1453.84 502.698 1453.84 502.698C1468.1 537.146 1499 551.222 1499 551.222C1499 551.222 1486.65 563.867 1450.86 563.589C1439.93 563.503 1428.93 558.484 1418.93 551.568C1438.23 570.999 1460.85 582.828 1460.85 582.828C1416.04 574.254 1386 522.384 1382.92 516.874C1379.12 522.479 1371.86 534.411 1371.25 544.486C1370.42 558.179 1375.13 584.767 1367.29 585.774C1367.29 585.774 1340.88 550.119 1353.84 523.005Z"
              fill="rgb(114 52 15)"/>
            <path
              d="M1355.31 765.201C1358.2 756.513 1377.56 722.75 1377.56 722.75C1377.56 722.75 1377.57 722.753 1377.57 722.755C1377.65 722.678 1389.87 711.477 1423.85 698.135C1423.85 698.135 1423.85 698.156 1423.83 698.197C1423.29 700.183 1408.79 752.72 1414.09 763.935C1419.47 775.336 1521.29 738.525 1526.86 730.124C1531.31 723.378 1531.43 687.726 1531.31 673.842C1531.29 670.386 1531.24 668.276 1531.24 668.276C1531.24 668.276 1569.26 663.124 1590.63 671.773C1590.63 671.773 1582.19 690.106 1579.08 710.322C1578.79 712.247 1578.55 714.171 1578.36 716.095C1577.95 720.172 1577.82 724.247 1578.05 728.221C1578.36 733.476 1581.07 738.132 1585.4 742.685C1589.33 746.865 1594.67 750.982 1600.71 755.389C1604.66 758.244 1608.9 761.245 1613.29 764.493L1613.29 764.495C1613.66 788.299 1611.98 827.445 1616.39 869.215C1617.51 879.809 1621.03 889.949 1626.57 898.928C1628.77 902.508 1631.29 905.902 1634.1 909.068C1656.56 934.25 1696.37 984.448 1687.61 1015.69C1686.97 1045.16 1438.44 1083.87 1443.76 1051.51C1443.76 1051.51 1406.41 871.512 1386.24 828.059C1385.51 826.466 1384.85 824.914 1384.25 823.32C1377.78 806.17 1378.88 788.535 1381.06 772.751C1369.67 764.656 1355.31 765.201 1355.31 765.201Z"
              fill="#F99746"/>
            <path
              d="M1510.68 991.029C1559.7 990.511 1601.2 939.61 1626.57 898.93C1621.03 889.949 1617.51 879.81 1616.39 869.216C1611.98 827.446 1613.66 788.3 1613.29 764.496L1613.29 764.494C1608.9 761.245 1604.66 758.244 1600.71 755.389C1594.67 750.982 1589.33 746.864 1585.4 742.685C1545.25 810.534 1444.21 991.733 1510.68 991.029Z"
              fill="#ED7D2B"/>
            <path
              d="M1325.39 1017.7L1332.1 1042.24C1332.1 1042.24 1340.54 1040.71 1353.97 1038.22C1358 1037.45 1360.3 1036.88 1360.3 1036.88C1371.04 1034.77 1383.89 1031.89 1397.89 1028.82H1398.08C1422.06 1023.06 1448.91 1016.16 1471.54 1008.49C1487.46 1002.93 1501.27 996.982 1510.67 991.035C1511.63 990.459 1512.4 989.883 1513.36 989.115C1535.8 972.814 1562.46 924.864 1583.17 873.46C1586.05 866.364 1588.74 859.267 1591.42 852.362C1601.01 826.659 1608.68 801.342 1613.28 779.476C1616.16 766.435 1618.08 754.541 1618.46 744.759C1618.66 741.308 1618.85 738.049 1618.66 734.977C1618.27 679.356 1590.65 671.683 1590.65 671.683C1590.08 671.491 1589.5 671.299 1588.73 671.11C1583.94 670.342 1578.95 673.601 1573.77 679.932C1567.25 687.794 1560.35 700.646 1553.25 716.18C1542.32 740.923 1530.81 772.955 1520.07 804.795C1514.12 822.247 1508.37 839.702 1502.61 855.812C1499.35 865.594 1496.09 874.8 1493.03 883.241C1487.08 899.351 1481.71 912.393 1476.91 920.641C1473.84 926.012 1465.02 933.493 1453.32 941.931C1432.99 956.7 1403.26 974.538 1377.37 989.113C1366.63 995.252 1356.46 1000.81 1348.02 1005.42C1334.41 1012.9 1325.39 1017.7 1325.39 1017.7Z"
              fill="#FFB27D"/>
            <path
              d="M1553.32 716.262C1590.24 706.083 1618.73 734.913 1618.73 734.913C1618.26 679.38 1590.62 671.779 1590.62 671.779C1579.5 667.174 1566.61 686.433 1553.32 716.262Z"
              fill="#F99746"/>
            <path
              d="M1606.06 1858.35H1733.71C1733.71 1858.35 1736.77 1838.9 1730.1 1824.14C1723.72 1809.98 1713.71 1803.54 1716.1 1786.4C1707.99 1794.75 1694.77 1804.1 1676.32 1801.62C1674.06 1805.29 1661.72 1824.91 1654.17 1830.33C1645.83 1836.33 1608.63 1834.19 1606.06 1858.35Z"
              fill="#233862"/>
            <path
              d="M1331.98 1858.35H1459.63C1459.63 1858.35 1464.4 1837.99 1456.02 1824.14C1448.6 1811.88 1451.44 1804.9 1456.94 1794.75C1451.16 1797.12 1443.67 1799.27 1435.44 1798.8C1429.08 1798.44 1422.35 1795.55 1416.45 1792.13C1410.96 1798.39 1387.44 1825.05 1380.08 1830.33C1371.74 1836.33 1334.55 1834.19 1331.98 1858.35Z"
              fill="#233862"/>
            <path
              d="M1045.88 1409.97C1045.88 1409.97 1097.32 1774.82 1091.39 1819.86L1123.85 1815.92C1123.85 1815.92 1164.47 1656.66 1159.59 1612.09C1154.7 1567.53 1199.84 1383.85 1158.2 1367.7C1116.55 1351.55 1045.88 1409.97 1045.88 1409.97Z"
              fill="#F0995E"/>
            <path
              d="M1092.67 1410.41C1092.67 1410.41 1166.04 1775.6 1162.89 1820.92L1195 1813.5C1195 1813.5 1226.22 1649.33 1218.6 1605.15C1210.98 1560.97 1244.72 1374.86 1202.16 1361.31C1159.6 1347.75 1092.67 1410.41 1092.67 1410.41Z"
              fill="#FFB27D"/>
            <path
              d="M1091.78 1813.45C1091.78 1813.45 1084.06 1827.62 1069.37 1830.91C1060.11 1832.98 1052.2 1837.85 1047.3 1844.64C1043.16 1850.38 1047.24 1858.4 1054.32 1858.35L1129 1857.93C1135.36 1857.89 1140.3 1852.38 1139.62 1846.05C1139.04 1840.52 1138.42 1834.08 1138.14 1829.14C1137.52 1818.36 1125.84 1808.67 1125.84 1808.67C1125.84 1808.67 1115.59 1814.56 1111.64 1815.25C1103.03 1816.76 1091.78 1813.45 1091.78 1813.45Z"
              fill="#2C3A64"/>
            <path
              d="M1162 1813.45C1162 1813.45 1154.29 1827.62 1139.59 1830.91C1130.34 1832.98 1122.43 1837.85 1117.53 1844.64C1113.39 1850.38 1117.47 1858.4 1124.55 1858.35L1199.23 1857.93C1205.59 1857.89 1210.52 1852.38 1209.85 1846.05C1209.26 1840.52 1208.64 1834.08 1208.36 1829.14C1207.75 1818.36 1196.07 1808.67 1196.07 1808.67C1196.07 1808.67 1185.82 1814.56 1181.86 1815.25C1173.26 1816.76 1162 1813.45 1162 1813.45Z"
              fill="#35488B"/>
            <path
              d="M1077.54 1093.91C1077.54 1093.91 1028.74 1176.19 1018.9 1243.28C1018.9 1243.28 1042.63 1275.31 1052.29 1252.82C1061.94 1230.33 1131.23 1109.94 1077.54 1093.91Z"
              fill="#FFB27D"/>
            <path
              d="M1086.83 947.846C1087.36 946.975 1109.2 912.445 1150.02 905.555C1183.09 899.975 1214.04 923.337 1219.13 956.487C1224.24 989.8 1219.03 1032.96 1164.68 1048.51L1086.83 947.846Z"
              fill="rgb(114 52 15)"/>
            <path
              d="M1096.83 1096.28L1158.34 1132.81L1174.57 1089.57C1174.57 1089.57 1166.75 1073.75 1164.68 1048.51C1163.81 1037.9 1163.83 1025.62 1165.67 1012.13L1112.19 1035.35C1112.19 1035.35 1113.12 1040.22 1113.5 1047.34C1113.9 1055.03 1113.63 1065.35 1110.84 1075C1108.43 1083.28 1104.17 1091.05 1096.83 1096.28Z"
              fill="#FFB27D"/>
            <path
              d="M1238.19 1481.87C1232.45 1416.14 1223.88 1329.72 1218.49 1294.24C1216.71 1282.54 1216.61 1270.65 1218.22 1258.93L1230.87 1165.59L1234.85 1136.3C1236.95 1120.8 1228.34 1105.82 1213.92 1099.78C1200.77 1094.3 1191.14 1093.02 1174.57 1089.57C1164.28 1098.86 1144.28 1112.2 1123.12 1108.07C1107.21 1104.95 1104.06 1097.08 1104.92 1087.95C1100.88 1088.35 1097.18 1088.09 1093.73 1088.57C1080.4 1090.38 1068.91 1097.78 1061.62 1108.35C1061.62 1108.34 1061.61 1108.3 1061.61 1108.3L1061.35 1108.75C1060.2 1110.44 1059.16 1112.21 1058.24 1114.06L1034.86 1154.03C1034.86 1154.03 1037.12 1167.95 1048.61 1174.36C1037.12 1300.86 1023.82 1586.49 1034.55 1590.15C1104.04 1613.85 1227.33 1580.88 1244.02 1564.93C1244.87 1564.12 1242.23 1528.11 1238.19 1481.87Z"
              fill="#2C3A64"/>
            <path
              d="M1086.32 1096.26C1050.31 1125.1 1038.22 1197.77 1042.95 1247.23C1051.97 1224.77 1051.39 1191.46 1060.14 1156.1C1068.64 1121.72 1092.37 1094.92 1092.37 1094.92C1091.47 1094.92 1086.32 1096.26 1086.32 1096.26Z"
              fill="#FFB93E"/>
            <path
              d="M1110.84 1075C1140.34 1076.08 1164.68 1048.51 1164.68 1048.51L1113.5 1047.34C1113.9 1055.03 1113.63 1065.35 1110.84 1075Z"
              fill="#ED985F"/>
            <path
              d="M1097.6 1057.68C1097.6 1057.68 1155.36 1076.93 1178.6 1031.5C1203.68 982.476 1182.02 944.218 1162.23 940.472C1122.76 933.001 1098.68 946.7 1090.67 962.289C1082.66 977.878 1065.71 1047.35 1097.6 1057.68Z"
              fill="#FFB27D"/>
            <path
              d="M1122.56 1032.86C1122.56 1032.86 1119.72 1038.76 1104.44 1034.38C1104.44 1034.38 1091.61 1030.7 1093.52 1023.94C1093.52 1023.94 1107.21 1021.86 1122.56 1032.86Z"
              fill="white"/>
            <path
              d="M1238.19 1481.87C1236.36 1479.75 1119.44 1343.34 1115.23 1270.77C1111 1197.63 1173.89 1144.19 1173.89 1144.19C1198.94 1142.54 1217.45 1150.74 1230.87 1165.59L1218.22 1258.93C1216.61 1270.65 1216.71 1282.54 1218.49 1294.24C1223.88 1329.72 1232.45 1416.14 1238.19 1481.87Z"
              fill="#232E4F"/>
            <path
              d="M1192.26 1091.58C1136.47 1104.44 1121.47 1196.63 1147.1 1267.21C1166.12 1319.58 1224.77 1344.53 1224.77 1344.53C1231.14 1337.37 1226.22 1321.27 1226.22 1321.27C1140.21 1266.45 1154.67 1188.96 1174.57 1149.86C1194.47 1110.77 1198.47 1096.26 1227.84 1106.76C1224.17 1090.34 1192.26 1091.58 1192.26 1091.58Z"
              fill="#FFB93E"/>
            <path
              d="M1138.07 945.864C1138.07 945.864 1132.59 1000.05 1184 1019.25C1204.44 1026.89 1215.08 944.125 1172.37 927.6C1129.66 911.074 1087.54 930.7 1082.5 959.59C1077.47 988.48 1081.3 994.503 1081.3 994.503C1081.3 994.503 1101.3 939.542 1138.07 945.864Z"
              fill="rgb(114 52 15)"/>
            <path
              d="M1169.28 1123.12C1169.28 1123.12 1145.71 1216.37 1144.79 1253.46C1144.79 1253.46 1093.05 1343.82 1090.01 1350.24C1086.98 1356.67 1108.69 1365.45 1108.69 1365.45C1108.69 1365.45 1173.6 1296.23 1186.11 1267.14C1198.62 1238.05 1243.01 1143.46 1225.24 1115.22C1207.48 1086.99 1169.28 1123.12 1169.28 1123.12Z"
              fill="#FFB27D"/>
            <path
              d="M1018.9 1243.28C1018.9 1243.28 1014.26 1306.79 1059.47 1356.83C1059.47 1356.83 1071.81 1341.98 1070.25 1337.37C1068.69 1332.76 1051 1261.97 1038.08 1251.9C1025.16 1241.83 1018.9 1243.28 1018.9 1243.28Z"
              fill="#FFB27D"/>
            <path
              d="M1090.01 1350.24C1090.01 1350.24 1061.08 1350.03 1059.47 1356.83C1057.85 1363.63 1066.9 1384.92 1088.06 1377.51C1109.22 1370.09 1108.69 1365.45 1108.69 1365.45L1090.01 1350.24Z"
              fill="#FFB27D"/>
            <path
              d="M1070.25 1337.37C1070.25 1337.37 1093.49 1348.11 1085.59 1359.56C1077.7 1371.01 1061.91 1364.16 1059.47 1356.83C1057.02 1349.51 1070.25 1337.37 1070.25 1337.37Z"
              fill="#FFB27D"/>
            <path
              d="M1152.26 1172.32C1152.26 1172.32 1182.6 1200.54 1229.38 1196.17C1229.38 1196.17 1252.43 1114.47 1215.46 1104.44C1178.5 1094.41 1147.95 1142.83 1152.26 1172.32Z"
              fill="#2C3A64"/>
            <path
              d="M791.334 635.877C791.334 635.877 861.33 745.313 839.189 945.274C832.278 1007.69 1051.12 1106.76 1051.12 1106.76C1051.12 1106.76 1067.56 1093.9 1060.52 1070.85C1060.52 1070.85 939.04 949.608 926.694 934.722C915.742 921.514 886.091 780.823 873.305 733.989C851.319 653.453 791.334 635.877 791.334 635.877Z"
              fill="#233862"/>
            <path
              d="M470.205 1746.72C470.205 1746.72 446.243 1768.58 450.919 1784.12C455.593 1799.66 477.216 1810.02 480.14 1829C483.062 1848 494.749 1858.35 505.269 1858.35C515.787 1858.35 555.421 1858.35 555.421 1858.35C555.421 1858.35 563.165 1844.99 544.464 1840.96C525.761 1836.93 518.711 1814.62 519.879 1793.9C521.048 1773.19 518.127 1745.56 518.127 1745.56L478.386 1736.36L470.205 1746.72Z"
              fill="#233862"/>
            <path
              d="M764.168 1803.27C764.168 1803.27 759.867 1853.04 770.387 1858.22L905.014 1857.94C905.014 1857.94 905.014 1842.4 883.391 1838.95C861.766 1835.5 823.779 1822.84 817.351 1794.64C810.922 1766.44 764.168 1803.27 764.168 1803.27Z"
              fill="#233862"/>
            <path
              d="M877.83 1100.3C877.83 1100.3 870.73 1187.53 862.385 1292.39C851.039 1434.83 837.399 1609.8 836.05 1642.94C833.717 1700.5 824.355 1792.56 824.355 1792.56C824.355 1792.56 802.159 1814.44 757.73 1805.22C757.73 1805.22 718.144 1673.79 706.834 1534.73C706.659 1532.46 706.483 1530.16 706.308 1527.86C695.788 1386.3 679.431 1088.21 679.431 1088.21L877.83 1100.3Z"
              fill="#FFB93E"/>
            <path
              d="M877.83 1100.3C877.83 1100.3 870.73 1187.53 862.385 1292.39L706.834 1534.73C706.659 1532.46 706.483 1530.16 706.308 1527.86C695.788 1386.3 679.431 1088.21 679.431 1088.21L877.83 1100.3Z"
              fill="#E3A330"/>
            <path
              d="M845.402 1110.66C845.402 1110.66 717.415 1426.58 633.259 1575.05C549.1 1723.51 522.801 1764.94 522.801 1764.94C522.801 1764.94 474.588 1745.95 470.205 1733.01C470.205 1733.01 502.384 1446.39 558.744 1376.52C590.589 1337.03 627.997 1218.55 629.752 1177.12C631.504 1135.69 641.146 1053.68 641.146 1053.68L845.402 1110.66Z"
              fill="#FFB93E"/>
            <path
              d="M677.008 623.793C677.008 623.793 763.606 665.575 813.531 650.074C813.531 650.074 801.24 634.57 779.736 623.035C781.463 613.016 782.808 604.318 783.577 596.945C785.689 579.928 785.689 570.284 785.689 570.284L752.279 544.008L720.596 518.86C723.091 589.57 677.008 623.793 677.008 623.793Z"
              fill="#FFB27D"/>
            <path
              d="M883.295 1107.5C881.766 1108.83 880.034 1109.57 878.098 1109.57C859.288 1109.19 804.564 1119.58 758.301 1114.86C719.127 1111.08 684.565 1099.74 647.504 1083.49C642.509 1081.22 631.17 1075.17 631.372 1069.88C631.575 1063.82 632.137 1055.32 632.901 1044.34C634.453 1022.74 636.638 992.068 637.334 955.706C637.851 928.749 637.559 898.666 635.602 866.803C635.488 864.666 635.377 862.507 635.286 860.347C634.724 848.669 634.453 836.899 634.453 825.401C634.25 788.343 636.929 752.991 642.306 721.424C651.736 666.586 669.214 623.366 695.315 604.262C698.779 601.607 720.475 612.313 740.435 621.967C758.685 630.856 776.911 631.802 794.192 628.402L811.473 643.523C832.422 662.604 855.463 688.885 865.632 728.601C874.07 762.262 879.065 823.13 881.766 886.289C883.095 915.023 884.062 943.759 884.444 971.165V972.112C884.647 974.945 884.647 977.803 884.647 980.639C885.591 1050.77 884.647 1106.56 883.295 1107.5Z"
              fill="#2B478B"/>
            <path
              d="M720.019 545.897C720.019 545.897 741.908 585.79 783.577 596.945C785.689 579.928 785.689 570.284 785.689 570.284L752.279 544.008L720.019 545.897Z"
              fill="#ED985F"/>
            <path
              d="M800.921 569.133C800.921 569.133 718.31 582.648 710.554 531.861C702.799 481.074 686.915 448.615 739.331 437.84C791.749 427.065 804.98 444.922 811.757 461.254C818.532 477.585 822.211 564.308 800.921 569.133Z"
              fill="#FFB27D"/>
            <path
              d="M680.53 431.759C679.779 430.899 678.05 430.205 674.199 429.996C674.199 429.996 677.276 428.071 681.392 427.971C682.195 422.616 685.044 413.143 694.256 410.84C694.256 410.84 689.306 413.48 688.658 419.169C699.328 408.212 722.193 400.234 785.505 405.233C868.945 411.819 841.446 465.241 830.642 471.61C819.84 477.979 792.651 460.519 772.525 462.116C752.397 463.713 748.57 492.796 741.743 496.785C734.916 500.776 737.599 493.72 728.363 489.544C719.124 485.368 718.246 500.676 720.915 510.63C723.582 520.584 710.889 533.712 710.889 533.712C710.889 533.712 710.889 533.712 698.064 519.897C685.236 506.084 673.146 447.571 678.978 435.099C679.495 433.996 680.005 432.881 680.53 431.759Z"
              fill="rgb(55 21 19)"/>
            <path
              d="M1077.02 1079.37C1077.02 1079.37 1087.59 1074.63 1093.9 1081.44C1100.22 1088.24 1114.97 1092.93 1113.7 1096.48C1111.08 1103.85 1097.47 1095.55 1083.09 1100.02C1078.05 1101.58 1062.45 1091.95 1077.02 1079.37Z"
              fill="#FFB27D"/>
            <path
              d="M637.335 955.705C637.852 928.748 637.56 898.665 635.603 866.802C635.489 864.665 635.378 862.506 635.287 860.346C643.14 821.44 655.021 786.924 672.504 785.797C672.504 785.797 724.595 867.658 637.335 955.705Z"
              fill="#233862"/>
            <path
              d="M568.432 1096.87C568.432 1096.87 572.774 1139.08 577.539 1147.06C582.302 1155.05 602.586 1180.57 611.227 1185.57C619.868 1190.58 605.95 1151.95 607.711 1144.44C609.47 1136.92 614.822 1158.15 618.629 1158.65C622.438 1159.15 621.458 1140.04 621.594 1133.09C621.73 1126.13 603.066 1108.96 596.485 1094.16C589.904 1079.35 568.432 1096.87 568.432 1096.87Z"
              fill="#FFB27D"/>
            <path
              d="M695.542 604.152C695.542 604.152 643.271 628.902 618.21 666.954C579.336 725.976 534.274 941.089 533.442 961.009C532.609 980.927 566.331 1121.56 566.331 1121.56C566.331 1121.56 600.533 1119.31 609.354 1102.04C609.354 1102.04 602.52 957.549 606.851 948.26C611.182 938.971 749.434 754.433 733.887 715.056C718.342 675.679 695.542 604.152 695.542 604.152Z"
              fill="#2B478B"/>
            <path
              d="M1245.99 1041.48C1258.26 1046.66 1275.52 1064.69 1303.14 1053.76C1322.9 1046.08 1343.8 1040.71 1353.97 1038.22C1358 1037.45 1360.3 1036.88 1360.3 1036.88L1349.37 1005.04C1349.37 1005.04 1348.98 1005.23 1348.02 1005.42C1340.93 1006.96 1304.87 1014.63 1287.99 1010.79C1269 1006.38 1237.16 1029.2 1237.16 1029.2C1237.16 1029.2 1233.71 1036.49 1245.99 1041.48Z"
              fill="#FFB27D"/>
            <path
              d="M2050.86 1867.27H137.861C136.186 1867.27 134.815 1865.9 134.815 1864.22V1860.33C134.815 1858.65 136.186 1857.28 137.861 1857.28H2050.86C2052.54 1857.28 2053.91 1858.65 2053.91 1860.33V1864.22C2053.91 1865.9 2052.54 1867.27 2050.86 1867.27Z"
              fill="#2E3552"/>
            <path
              d="M1168.22 342.573H999.752C977.618 342.573 959.507 360.683 959.507 382.818V446.553C959.507 456.667 963.297 465.934 969.516 473.024L959.507 518.777L1017.23 486.798H1168.22C1190.36 486.798 1208.47 468.688 1208.47 446.553V382.818C1208.47 360.683 1190.36 342.573 1168.22 342.573Z"
              fill="#D2E3FC"/>
            <path
              d="M1171.39 391.133H996.739C993.144 391.133 990.202 388.191 990.202 384.596C990.202 381.001 993.143 378.059 996.739 378.059H1171.4C1174.99 378.059 1177.93 381 1177.93 384.596C1177.93 388.191 1174.99 391.133 1171.39 391.133Z"
              fill="#B7D3F3"/>
            <path
              d="M1171.39 419.739H996.739C993.144 419.739 990.202 416.798 990.202 413.202C990.202 409.607 993.143 406.665 996.739 406.665H1171.4C1174.99 406.665 1177.93 409.606 1177.93 413.202C1177.93 416.798 1174.99 419.739 1171.39 419.739Z"
              fill="#B7D3F3"/>
            <path
              d="M1171.39 447.272H996.739C993.144 447.272 990.202 444.331 990.202 440.735C990.202 437.14 993.143 434.199 996.739 434.199H1171.4C1174.99 434.199 1177.93 437.14 1177.93 440.735C1177.93 444.331 1174.99 447.272 1171.39 447.272Z"
              fill="#B7D3F3"/>
        </g>
        <defs>
            <clipPath id="clip0_3_2">
                <rect width="2213.55" height="2000" fill="white"/>
            </clipPath>
        </defs>
    </svg>


  );
}

