import {AdminUser} from "../../../domain/common/AdminUser";

export const SERVER_ADMIN_USERS_SET = 'admin/server/admin-users/set';

export const initialState: {
  server: { adminUsers: AdminUser[] },
  local: {}
} = {
  server: {
    adminUsers: []
  },
  local: {}
};

const AdminUserReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case SERVER_ADMIN_USERS_SET:
      return {
        ...state,
        server: {
          ...state.server,
          adminUsers: action.adminUsers
        }
      };
  default:
    return state;
  }
};

export default AdminUserReducers;
