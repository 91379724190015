import { currentUserIdSelector } from '../auth/AuthSelectors';
import {mapValues, pick} from 'lodash';
import {createDeepEqualSelector} from "../../StateUtils";

export const baseProfileSelector = (state: any) => state.baseProfile;

// Profiles

export const profileServerSelector = (id: string) =>
  createDeepEqualSelector(baseProfileSelector, (profileState) => id ? profileState.server.profiles[id]?.entity : undefined);

export const profileServerByIdsSelector = (ids: string[]) =>
  createDeepEqualSelector(baseProfileSelector, (profileState) =>
    ids ? mapValues(pick(profileState.server.profiles, ids) || {}, profileEntity => profileEntity.entity) : undefined);

export const profileCurrentSelector = () =>
  createDeepEqualSelector(baseProfileSelector, currentUserIdSelector(), (profileState, currentUserId) =>
    !currentUserId || !profileState.server.profiles[currentUserId]?.entity ? undefined :
      {id: currentUserId, ...profileState.server.profiles[currentUserId]?.entity});

export const profileCurrentFetchStatusSelector = () =>
  createDeepEqualSelector(baseProfileSelector, currentUserIdSelector(), (profileState, currentUserId) =>
    profileState.server.profiles[currentUserId]?.status);

// Roles

export const profileCurrentUserRoles = () =>
  createDeepEqualSelector(baseProfileSelector, (profileState) => profileState.server.currentUserRoles);