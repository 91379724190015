import React from 'react';
import {
  Box, Button, Card, CardContent,
  Typography, useMediaQuery, useTheme
} from "@mui/material";
import iPhoneMockup from'../../components/image/iPhoneMockup.png';
import ManagementGraphic from'../../components/image/ManagementGraphic.png';
import ProfessorGraphic from'../../components/image/ProfessorGraphic.png';
import FamiliesGraphic from'../../components/image/FamiliesGraphic.png';
import TrueFriendsGraphic from'../../components/image/TrueFriendsGraphic.png';
import ManagementChartsSVG from "../../components/image/svg/ManagementChartsSVG";
import MobileChatSVG from "../../components/image/svg/MobileChatSVG";
import ActivityMonitoringSVG from "../../components/image/svg/ActivityMonitoringSVG";
import './Home.css'
import { useNavigate } from "react-router-dom";
import { SxProps } from "@mui/system";
import TriangleSVG from "../../components/image/svg/TriangleSVG";

export default function Home(){
  const navigate = useNavigate();
  const theme = useTheme();
  const smUpScreen = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      { smUpScreen &&
        <>
          <TriangleSVG id='green-triangle-1'
                       color='#5DCB67'
                       style={{ position: 'absolute',  top: '20%', right: '35%', width: 25, height: 25, transform: 'rotate(5deg)' }}/>
          <TriangleSVG id='yellow-triangle-1'
                       color='#FEC72A'
                       style={{ position: 'absolute', top: '90%', left: '35%', width: 25, height: 25, transform: 'rotate(5deg)' }}/>
          <TriangleSVG id='orange-triangle-1'
                       color='#F27C7A'
                       style={{ position: 'absolute', top: '102%', left: '20%', width: 25, height: 25, transform: 'rotate(-10deg)' }}/>
          <TriangleSVG id='violet-triangle-1'
                       color='#8D6BDB'
                       style={{ position: 'absolute', top: '100%', left: '50%', width: 25, height: 25, transform: 'rotate(5deg)' }}/>
          <TriangleSVG id='red-triangle-1'
                       color='#F53F3B'
                       style={{ position: 'absolute', top: '100%', right: '7%', width: 40, height: 40, transform: 'rotate(-40deg)' }}/>
          <TriangleSVG id='green-triangle-2'
                       color='#5DCB67'
                       style={{ position: 'absolute', top: '110%', left: '30%', width: 25, height: 25, transform: 'rotate(45deg)' }}/>
          <TriangleSVG id='red-triangle-2'
                       color='#F53F3B'
                       style={{ position: 'absolute', top: '120%', right: '40%', width: 40, height: 40, transform: 'rotate(-40deg)' }}/>
        </>
      }

      <Box sx={styles.previewBox}>
        <Box style={styles.previewInnerBox}>
          <Box className={"rotate-15"}
               style={styles.previewIPhoneMockupBox}
               sx={{ display: { xs: 'none', md: 'block' } }}>
            <img src={iPhoneMockup} style={{width: '100%'}} alt="iPhoneMockup"/>
          </Box>

          <Box style={styles.previewContentBox}>
            <Typography sx={sxStyles.previewTitle}>
              Stay close<br/>
              to every moment
            </Typography>
            <Typography sx={sxStyles.previewDescription}>
              Bringing together families, learning and activity spaces in one<br/>
              shared profile, <b><span style={{color: '#2390F0'}}>KidsLog</span></b> is the unified platform to organize, <br/>
              observe, and collaborate on every child’s journey
            </Typography>

            <Button style={styles.containedButton}
                    disableElevation={true}
                    variant="contained"
                    key={'item'}
                    onClick={() => {
                      navigate("/demo-request");
                      window.scrollTo(0, 0);
                    }}>
              Request a Demo
            </Button>
          </Box>
        </Box>
      </Box>

      <Box sx={sxStyles.whatSectionBox}>
        <Card style={{...styles.raisedCard}} raised>
          <CardContent style={styles.cardContent}>
            <ActivityMonitoringSVG style={styles.whatSectionSvg}/>
            <Typography sx={sxStyles.whatSectionTitle}>
              Daily activity tracking
            </Typography>
            <Typography sx={sxStyles.whatSectionDescription}>
              Tracking child’s daily routines and activities effortlessly, keeping parents in the loop.
            </Typography>
          </CardContent>
        </Card>
        <Card style={{...styles.raisedCard, flex: 1}} raised>
          <CardContent style={styles.cardContent}>
            <MobileChatSVG style={styles.whatSectionSvg}/>
            <Typography sx={sxStyles.whatSectionTitle}>
              Communication<br/>
              and notification
            </Typography>
            <Typography sx={sxStyles.whatSectionDescription}>
              Direct messages and notifications keep admins, staff, and families connected at all times.
            </Typography>
          </CardContent>
        </Card>
        <Card style={{...styles.raisedCard}} raised>
          <CardContent style={styles.cardContent}>
            <ManagementChartsSVG style={styles.whatSectionSvg}/>
            <Typography sx={sxStyles.whatSectionTitle}>
              Better Planing,<br/>
              smart tracking
            </Typography>
            <Typography sx={sxStyles.whatSectionDescription}>
              Whether you’re managing a center or your child’s schedule, KidsLog simplifies planning, tracking, and reporting.
            </Typography>
          </CardContent>
        </Card>
      </Box>

      <Box style={styles.whoSectionBox}>
        { smUpScreen &&
          <>
            <TriangleSVG id='red-triangle-3'
                         color='#F53F3B'
                         style={{ position: 'absolute', top: '15%', right: '5%', width: 40, height: 40, transform: 'rotate(-40deg)' }}/>
            <TriangleSVG id='violet-triangle-2'
                         color='#8D6BDB'
                         style={{ position: 'absolute', top: '40%', left: '0', width: 25, height: 25, transform: 'rotate(5deg)' }}/>
            <TriangleSVG id='yellow-triangle-2'
                         color='#FEC72A'
                         style={{ position: 'absolute', top: '75%', right: '30%', width: 25, height: 25, transform: 'rotate(45deg)' }}/>
            <TriangleSVG id='light-blue-triangle-1'
                         color='#91C7F8'
                         style={{ position: 'absolute', top: '80%', right: '10%', width: 25, height: 25, transform: 'rotate(-10deg)' }}/>
            <TriangleSVG id='orange-triangle-2'
                         color='#F27C7A'
                         style={{ position: 'absolute', top: '90%', right: '20%', width: 25, height: 25, transform: 'rotate(-10deg)' }}/>
          </>
        }

        <Box sx={sxStyles.whoSectionContentBox}>
          { smUpScreen &&
            <img src={TrueFriendsGraphic}
                 style={styles.whoSectionContentImg}
                 alt="TrueFriendsGraphic"/>
          }
          <Box style={styles.whoSectionContent}>
            <Typography style={styles.whoSectionTitle}>
              Child at the center
            </Typography>
            <Typography style={styles.whoSectionDescription}>
              We believe each child’s
              growth is too dynamic to fit neatly within one setting. KidsLog was built to adapt to each child’s
              unique world, allowing you to create a profile that’s entirely centered on your child.

              Whether they’re at school, on the playground, at after-school activities, or sports practice, all their
              updates and progress can flow seamlessly into one place, accessible to parents and caregivers.
            </Typography>
          </Box>
        </Box>
        <Box sx={sxStyles.whoSectionContentBox}>
          <Box style={styles.whoSectionContent}>
            <Typography style={styles.whoSectionTitle}>
              Owners and directors
            </Typography>
            <Typography style={styles.whoSectionDescription}>
              Manage all parent communication in one place, making it easy for
              teachers and admins to keep everyone informed. Track attendance,
              manage sign-ins and sign-outs, and ensure that every child’s day is
              accounted for safely.
            </Typography>
          </Box>
          { smUpScreen &&
            <img src={ManagementGraphic}
                 style={styles.whoSectionContentImg}
                 alt="ManagementGraphic"/>
          }
        </Box>
        <Box sx={{ ...sxStyles.whoSectionContentBox, justifyContent: 'end' }}>
          { smUpScreen &&
            <img src={ProfessorGraphic}
                 style={styles.whoSectionContentImg}
                 alt="ProfessorGraphic"/>
          }
          <Box style={styles.whoSectionContent}>
            <Typography style={styles.whoSectionTitle}>
              Professors
            </Typography>
            <Typography style={styles.whoSectionDescription}>
              Save Time on Daily Updates by quickly sharing important moments,
              daily activities, and student progress with parents. Keep in touch
              with parents through a single app, whether it’s a quick update
              or a detailed report.
            </Typography>
          </Box>
        </Box>
        <Box sx={ sxStyles.whoSectionContentBox }>
          <Box style={styles.whoSectionContent}>
            <Typography style={styles.whoSectionTitle}>
              Families
            </Typography>
            <Typography style={styles.whoSectionDescription}>
              Stay in the Loop! Receive real-time updates on your child’s activities,
              photos, and key milestones right on your phone. Send messages, ask
              questions, and stay connected with your child’s teachers easily. With
              secure photo and video sharing, never miss a smile, a new friend, or
              an important achievement.
            </Typography>
          </Box>
          { smUpScreen &&
            <img src={FamiliesGraphic}
                 style={styles.whoSectionContentImg}
                 alt="FamiliesGraphic"/>
          }
        </Box>
      </Box>

      <Box sx={sxStyles.downloadBox}>

        { smUpScreen &&
          <TriangleSVG id='green-triangle-3'
                       color='#5DCB67'
                       style={{ position: 'absolute', top: '25%', left: '40%', width: 25, height: 25, transform: 'rotate(5deg)' }}/>
        }
        <Box style={styles.downloadContentBox}>
          <Typography sx={sxStyles.downloadTitle}>
            Download
          </Typography>
          <Typography style={styles.downloadDescription}>
            Owners, teachers, and families all access the same KidsLog app.
            Available for download on iOS and Android, for both phones and tablets.
          </Typography>

          <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <a href="https://apps.apple.com/us/app/kidslog/id6447705145?itsct=apps_box_badge&amp;itscg=30200"
               target="_blank" rel="noreferrer"
               style={{flex: .35}}>
              <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1682035200"
                   alt="Download on the App Store"
                   style={styles.downloadAppStoreImg}/>
            </a>
            <a href='https://play.google.com/store/apps/details?id=com.softruler.kidslog'
               target="_blank" rel="noreferrer"
               style={{flex: .35}}>
              <img alt='Get it on Google Play'
                   src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                   style={{width: '100%'}}/>
            </a>
          </Box>
        </Box>

        <Box sx={sxStyles.downloadIPhoneMockupBox}>
          <img src={iPhoneMockup} style={styles.downloadIPhoneMockupImg}
               alt="iPhoneMockup"/>
        </Box>
      </Box>

      <Box sx={sxStyles.getStartedBox}>
        <Typography sx={sxStyles.getStartedTitle}>
          Get started now 🤗 🥰
        </Typography>
        <Typography sx={sxStyles.getStartedDescription}>
          Discover everything KidsLog can do for you.<br/>
          Sign up and start using the app today!
        </Typography>
        <Button style={styles.containedButton}
                disableElevation={true}
                variant="contained"
                key={'item'}
                onClick={() => {
                  navigate("/demo-request");
                  window.scrollTo(0, 0);
                }}>
          Get Started
        </Button>
      </Box>

      <Box sx={sxStyles.contactUsBox}>
        <Box style={{flex: .5}}>
          <Typography sx={sxStyles.contactUsDescription}>
            We’d love to hear from you! Reach out and let us know how we can make your KidsLog experience even better.
          </Typography>
        </Box>
        <Box sx={sxStyles.contactUsBtnBox}>
          <Button style={styles.outlinedButton}
                  disableElevation={true}
                  variant="outlined"
                  key={'item'}
                  onClick={() => {
                    navigate("/contact");
                    window.scrollTo(0, 0);
                  }}>
            Contact Us
          </Button>
        </Box>
      </Box>
    </>
  )
}

const sxStyles: { [key: string]: SxProps } = {
  previewTitle: {
    fontFamily: 'Dela Gothic One',
    fontSize: { xs: 35, md: 50 },
    lineHeight: '120%',
    color: '#2B353F',
  },
  previewDescription: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '128.91%',
    color: '#2B353F',
    marginTop: { xs: '15%', sm: '8%' },
    marginBottom: { xs: '15%', sm: '8%' },
  },
  whatSectionBox: {
    position: 'relative',
    zIndex: 5,
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    marginTop: '15em',
    marginBottom: '10em',
    marginX: '8vw',
  },
  whatSectionTitle: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: {xs: 20, sm: 15, md: 20},
    lineHeight: '128.91%',
    textAlign: 'center',
    minHeight: '20vh',
  },
  whatSectionDescription: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: {xs: 20, sm: 13, md: 17},
    lineHeight: '130%',
    textAlign: 'center'
  },
  whoSectionContentBox: {
    marginTop: 10,
    display: 'flex',
    flexDirection: {xs: 'column', sm: 'row'},
  },
  downloadBox: {
    position: 'relative',
    backgroundColor: '#FAFAFB',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: { xs: 5, md: 10 },
    paddingLeft: '5vw',
    paddingRight: '5vw',
    marginTop: '10%',
  },
  downloadTitle: {
    fontFamily: 'Dela Gothic One',
    fontSize: { xs: 35, md: 50 },
    lineHeight: '120%',
  },
  downloadIPhoneMockupBox: {
    display: { xs: 'none', md: 'flex' },
    justifyContent: 'center',
    flex: 1,
    width: '100%',
    overflow: 'hidden'
  },
  getStartedBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: { xs: 5, md: 10 },
    paddingBottom: { xs: 5, md: 10 },
    paddingLeft: '6%',
    paddingRight: '6%',
  },
  getStartedTitle: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: { xs: 20, md: 35 },
    lineHeight: '130%',
  },
  getStartedDescription: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: { xs: 15, md: 18 },
    lineHeight: '130%',
    paddingTop: 5,
    paddingBottom: 5,
    textAlign: 'center'
  },
  contactUsBox: {
    backgroundColor: '#FAFAFB',
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    alignItems: 'center',
    paddingTop: 5,
    paddingLeft: '6%',
    paddingRight: '6%',
  },
  contactUsDescription: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: { xs: 15, md: 18 },
    lineHeight: '130%',
  },
  contactUsBtnBox: {
    flex: .5,
    display: 'flex',
    justifyContent: 'end',
    marginTop: { xs: 5, md: 0 },
    marginBottom: { xs: 5, md: 0 },
  }
};

const styles: { [key: string]: React.CSSProperties } = {
  previewBox: {
    flexGrow: 1,
    height: '90vh',
    flex: 1,
  },
  previewInnerBox: {
    height: '100%',
    position: 'relative',
  },
  previewIPhoneMockupBox: {
    position: 'absolute',
    width: '27vw',
    top: '10vh',
    right: '10vw',
  },
  previewContentBox: {
    position: 'absolute',
    left: '1em',
    top: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  containedButton: {
    width: 'fit-content',
    backgroundColor: '#2390F0',
    paddingLeft: 25,
    paddingTop: 15,
    paddingRight:25,
    paddingBottom: 15,
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '120%',
    textTransform: 'none',
    borderRadius: 12,
  },
  outlinedButton: {
    width: 'fit-content',
    borderColor: '#2390F0',
    color: '#2390F0',
    paddingLeft: 25,
    paddingTop: 15,
    paddingRight:25,
    paddingBottom: 15,
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '120%',
    textTransform: 'none',
    borderRadius: 12,
  },
  raisedCard: {
    flex: 1,
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: '2%',
    marginTop: '5%',
    marginRight: '2%',
    marginBottom: '5%',
  },
  whoSectionBox: {
    position: 'relative',
    marginLeft: '5vw',
    marginRight: '5vw',
  },
  whoSectionContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '10vw',
    marginRight: '10vw',
    zIndex: 2,
  },
  whoSectionTitle: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 35,
    lineHeight: '130%',
  },
  whoSectionDescription: {
    marginTop: 5,
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '130%',
  },
  whoSectionContentImg: {
    width: '15vw',
    objectFit: 'contain'
  },
  whatSectionSvg: {
    minHeight: 160,
    display: 'flex',
    alignItems: 'center',
  },
  downloadContentBox: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  downloadDescription: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '128.91%',
    paddingTop: '7vh',
    paddingBottom: '7vh',
  },
  downloadAppStoreImg: {
    borderRadius: 13,
    width: '100%',
    padding: '9%',
    marginLeft: '-9%'
  },
  downloadIPhoneMockupImg: {
    width: '75%',
    marginTop: '-5%',
    marginBottom: '-60%',
  },
}
