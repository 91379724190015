import {PricingPlan} from "../../../domain/common/PricingPlan";

export const SERVER_ADMIN_PRICING_PLANS_SET = 'admin/server/admin-pricing-plans/set';

export const initialState: {
  server: { pricingPlans: PricingPlan[] | undefined },
  local: {}
} = {
  server: {
    pricingPlans: undefined
  },
  local: {}
};

const AdminPricingReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case SERVER_ADMIN_PRICING_PLANS_SET:
      return {
        ...state,
        server: {
          ...state.server,
          pricingPlans: action.pricingPlans
        }
      };
  default:
    return state;
  }
};

export default AdminPricingReducers;
