import React from 'react'
import {Navigate, useLocation} from 'react-router-dom'
import {useSelector} from "react-redux";
import {isUserAuthenticatedSelector} from "../state/base/auth/AuthSelectors";
import {Box, CircularProgress} from "@mui/material";
import {SxProps} from "@mui/system";

interface Props {
  children: React.JSX.Element
}
const ProtectedRoute = ({ children }: Props) => {
  const location = useLocation();

  // Selectors
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector());

  return isUserAuthenticated == null
    ? <Box sx={sxStyles.progressBox}>
        <CircularProgress />
      </Box>
    : isUserAuthenticated
      ? children
      : <Navigate to="/auth/login" state={{ from: location }} />;
}

export default ProtectedRoute;

const sxStyles: { [key: string]: SxProps } = {
  progressBox: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}
